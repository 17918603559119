import React, {Component} from 'react';
import './App.css';
//
// import FilterRow from "./ContentComponents/FilterRow";
// import ProjectBox from "./ContentComponents/ProjectBox";
import {  withTranslation, Trans } from 'react-i18next';
import InfinityLoading from "./img/infinity.svg";
// import ProjectTextBox from "./ContentComponents/ProjectTextBox";
import { ProjectData } from './contentData';

import Helmet from "react-helmet/es/Helmet";
import ProjectsDetailBox from "./components/ProjectDetailBox";


class Projects extends Component {

    constructor(props) {
        super(props);

        this.timeout = null;
        this.page = 1;
        this.requestId = null;
        this.state = {
            activeFilters : {
                services : [],
                expertise : [],
                countries : []
            },
            projectTypes : "",
            filterChanged : false,
            moreProjects : true,
            loadingMore : false,
            noResults : false,
            projects : [],
            filters : {}
        };

       // this.filterOnChecked = this.filterOnChecked.bind(this);
        // this.loadMoreProjects = this.loadMoreProjects.bind(this);
    }

    // loadProjects(loadMore = false) {
    //     let url = new URL(window.API + '/projects/list');
    //     Object.keys(this.state.activeFilters).forEach(key => url.searchParams.append(key, this.state.activeFilters[key]));
    //
    //     if (loadMore) {
    //         this.page = this.page + 1;
    //         url.searchParams.append('page', this.page);
    //         this.setState({ loadingMore :  true });
    //     } else {
    //         this.requestId = (new Date()).getTime().toString();
    //         url.searchParams.append('requestId', this.requestId);
    //     }
    //
    //     fetch(url)
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.requestId && this.requestId !== data.requestId) { return; }
    //             this.setState(state => {
    //                 if (loadMore) {
    //                     state.projects.push(...data.projects);
    //                 } else {
    //                     state.projects = data.projects;
    //                 }
    //
    //                 state.noResults = data.projects.length === 0 && data.end;
    //                 state.moreProjects = !data.end;
    //                 state.filterChanged = false;
    //                 state.loadingMore = false;
    //                 state.loaded = true;
    //                 return state;
    //             });
    //         });
    // }

    componentDidMount() {
        const { i18n } = this.props;

        let url = new URL(window.location.href);

        console.log(url.pathname);

        if(url.pathname.includes("pozemni-stavby")){
            this.setState({
                projectTypes : "pozemni-stavby",
                projects: []
            }, function () {
                var ProjectDatasArray = [];

                ProjectData.inzenyrske_stavby.map((project, i) =>
                {
                    console.log("Entered", this.state.projects);
                    ProjectDatasArray.push(<ProjectsDetailBox name={project.name}
                                                              type={"pozemni"}
                                                              link={"/projekty/pozemni-stavby/"+project.id}
                                                              image_folder={project.image_folder}
                                                              kratky_popis={project.kratky_popis}
                                                              popis={project.popis}
                                                              architekt={project.architekt}
                                                              realizacni_firma={project.realizacni_firma}
                                                              projektant={project.projektant}
                                                              investicni_naklady={project.investicni_naklady}/>);
                    //   var joined = this.state.projects.concat( <ProjectsDetailBox name={project.name} type={"pozemni"} link={"/projekty/pozemni-stavby/projekt1"}/>);
                    // console.log("Entered2", joined);

                    // Return the element. Also pass key
                    //  return (<Answer key={answer} answer={answer} />)
                });
                this.setState({ projects: ProjectDatasArray });
            });
        }

        if(url.pathname.includes("inzenyrske-stavby")){
            this.setState({
                projectTypes : "inzenyrske-stavby",
                projects: []
            }, function () {
                var ProjectDatasArray = [];

                ProjectData.inzenyrske_stavby.map((project, i) =>
                {
                    console.log("Entered", this.state.projects);
                    ProjectDatasArray.push(<ProjectsDetailBox name={project.name}
                                                              type={"inzenyrske"}
                                                              link={"/projekty/inzenyrske-stavby/"+project.id}
                                                              image_folder={project.image_folder}
                                                              kratky_popis={project.kratky_popis}
                                                              popis={project.popis}
                                                              architekt={project.architekt}
                                                              realizacni_firma={project.realizacni_firma}
                                                              projektant={project.projektant}
                                                              investicni_naklady={project.investicni_naklady}/>);
                 //   var joined = this.state.projects.concat( <ProjectsDetailBox name={project.name} type={"pozemni"} link={"/projekty/pozemni-stavby/projekt1"}/>);
                   // console.log("Entered2", joined);

                    // Return the element. Also pass key
                  //  return (<Answer key={answer} answer={answer} />)
                });
                this.setState({ projects: ProjectDatasArray });

            });
        }


    }

    componentDidUpdate(prevProps, prevState) {



        const { i18n } = this.props;

        let url = new URL(window.location.href);

        console.log(url.pathname);

        if(url.pathname.includes("pozemni-stavby")){
            if (prevState.projectTypes !== "pozemni-stavby") {
                this.setState({
                    projectTypes : "pozemni-stavby",
                    projects: []
                }, function () {
                    var ProjectDatasArray = [];

                    ProjectData.inzenyrske_stavby.map((project, i) =>
                    {
                        console.log("Entered", this.state.projects);
                        ProjectDatasArray.push(<ProjectsDetailBox name={project.name}
                                                                  type={"pozemni"}
                                                                  link={"/projekty/pozemni-stavby/"+project.id}
                                                                  image_folder={project.image_folder}
                                                                  kratky_popis={project.kratky_popis}
                                                                  popis={project.popis}
                                                                  architekt={project.architekt}
                                                                  realizacni_firma={project.realizacni_firma}
                                                                  projektant={project.projektant}
                                                                  investicni_naklady={project.investicni_naklady}/>);
                        //   var joined = this.state.projects.concat( <ProjectsDetailBox name={project.name} type={"pozemni"} link={"/projekty/pozemni-stavby/projekt1"}/>);
                        // console.log("Entered2", joined);

                        // Return the element. Also pass key
                        //  return (<Answer key={answer} answer={answer} />)
                    });
                    this.setState({ projects: ProjectDatasArray });

                });
            }
        }
        if(url.pathname.includes("inzenyrske-stavby")){
            if (prevState.projectTypes !== "inzenyrske-stavby") {
                this.setState({
                    projectTypes : "inzenyrske-stavby",
                    projects: []
                }, function () {
                    var ProjectDatasArray = [];

                    ProjectData.inzenyrske_stavby.map((project, i) =>
                    {
                        console.log("Entered", this.state.projects);
                        ProjectDatasArray.push(<ProjectsDetailBox name={project.name}
                                                                  type={"inzenyrske"}
                                                                  link={"/projekty/inzenyrske-stavby/"+project.id}
                                                                  image_folder={project.image_folder}
                                                                  kratky_popis={project.kratky_popis}
                                                                  popis={project.popis}
                                                                  architekt={project.architekt}
                                                                  realizacni_firma={project.realizacni_firma}
                                                                  projektant={project.projektant}
                                                                  investicni_naklady={project.investicni_naklady}/>);
                        //   var joined = this.state.projects.concat( <ProjectsDetailBox name={project.name} type={"pozemni"} link={"/projekty/pozemni-stavby/projekt1"}/>);
                        // console.log("Entered2", joined);

                        // Return the element. Also pass key
                        //  return (<Answer key={answer} answer={answer} />)
                    });
                    this.setState({ projects: ProjectDatasArray });

                });
            }
        }
    }



    render() {
        const { i18n, t } = this.props;




        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('projects.seo.title') ? t('projects.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('projects.seo.description') ? t('projects.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('projects.seo.title') ? t('projects.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('projects.seo.description') ? t('projects.seo.description') : ""} />
                </Helmet>


                    { this.state.projectTypes === "pozemni-stavby" ?
                        <div className="container content fade-in-website" id="projects">

                            {this.state.projects}
                        </div>
                    : null}

                    { this.state.projectTypes === "inzenyrske-stavby" ?
                        <div className="container content fade-in-website" id="projects">
                           {/* <h3>Inženýrské stavby</h3>*/}
                            {this.state.projects}
                        {/*    <ProjectsDetailBox  link={"/projekty/inzenyrske-stavby/projekt1"}/>
                            <ProjectsDetailBox  link={"/projekty/inzenyrske-stavby/projekt1"}/>
                            <ProjectsDetailBox  link={"/projekty/inzenyrske-stavby/projekt1"}/>
                            <ProjectsDetailBox  link={"/projekty/inzenyrske-stavby/projekt1"}/>
*/}
                        </div>
                        : null}


            </div>
        );
    }
}

export default withTranslation()(Projects);

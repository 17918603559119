import React, {Component} from 'react';
import '../App.css';

import {  withTranslation, Trans } from 'react-i18next';
import {ProjectData} from "../contentData";


class ProjectsDetailBox extends Component {

    constructor(props) {
        super(props);

        this.timeout = null;
        this.page = 1;
        this.requestId = null;
        this.state = {
            activeFilters : {
                services : [],
                expertise : [],
                countries : []
            },
            loaded : false,
            filterChanged : false,
            moreProjects : true,
            loadingMore : false,
            noResults : false,
            projects : [],
            filters : {}
        };

    }


    componentDidMount() {


    }



    render() {
        const { i18n, t } = this.props;


        return (
            <div>


                    <div className={"col-md-3 project-detail-box"}>
                        <a href={this.props.link}>
                            {this.props.type === "pozemni" ?
                                <img src={"/img/projects/"+this.props.image_folder+"/thumbnail.jpg" } alt="foto"/>
                            :
                                <img src={"/img/projects/"+this.props.image_folder+"/thumbnail.jpg" } alt="foto"/>
                            }

                            <div className={"whiteOverlay"}>
                                <h4>
                                    {this.props.name}
                                </h4>
                                <span className="description" >
                                <em><span >     {this.props.kratky_popis}</span></em>
                                </span>
                            </div>

                        </a>
                    </div>


            </div>
        );
    }
}

export default withTranslation()(ProjectsDetailBox);

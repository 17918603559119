import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import $ from 'jquery';


import {  withTranslation, Trans } from 'react-i18next';
// import ProjectDetailImage from "./ContentComponents/ProjectDetailImage";
import InfinityLoading from "./img/infinity.svg";
import {Helmet} from "react-helmet";
import Slider from "react-slick/lib";
import mainSlideImg from "./img/mainSlider.jpg";
import {ProjectData} from "./contentData";


const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}
var images = null;
class ProjectsDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projectName: null,
            loaded:true,
            projectImageFolder: null,
            projectPopis: null,
            projectArchitekt: null,
            projectRealizacniFirma: null,
            projektant: null,
            investicniNaklady: null,
            projectImages: [],
            typeProject: ""
        };

     //   this.recalculateFactsHeights = this.recalculateFactsHeights.bind(this);
    }


    componentDidMount() {
        let url = new URL(window.location.href);
        var urlSplited = window.location.href.toString().split("/");
        var projectID = urlSplited[5];


        console.log(urlSplited[5]);

              if(url.pathname.includes("inzenyrske-stavby")){
                  this.setState({ typeProject: "inzenyrske-stavby"});
                  ProjectData.inzenyrske_stavby.map((project, i) =>
                  {
                      if(project.id === projectID){
                          this.setState({ projectName: project.name, projectImageFolder: project.image_folder,
                          projectPopis: project.popis, projectArchitekt: project.architekt, projectRealizacniFirma: project.realizacni_firma,
                          projektant: project.projektant, investicniNaklady: project.investicni_naklady});


                            var images = [];

                          for (var i = 0; i < project.imageCount; i++) {
                            images.push(<img src={"/img/projects/"+project.image_folder+"/"+i+".png"}/>)
                          }
                          this.setState({ projectImages: images});


                      }
                     // console.log("Entered", this.state.projects);
                  });

              }
        if(url.pathname.includes("pozemni-stavby")){
            this.setState({ typeProject: "pozemni-stavby"});
            ProjectData.inzenyrske_stavby.map((project, i) =>
            {
                if(project.id === projectID){
                    this.setState({ projectName: project.name, projectImageFolder: project.image_folder,
                        projectPopis: project.popis, projectArchitekt: project.architekt, projectRealizacniFirma: project.realizacni_firma,
                        projektant: project.projektant, investicniNaklady: project.investicni_naklady});


                    var images = [];

                    for (var i = 0; i < project.imageCount; i++) {
                        images.push(<img src={"/img/projects/"+project.image_folder+"/"+i+".png"}/>)
                    }
                    this.setState({ projectImages: images});


                }
                // console.log("Entered", this.state.projects);
            });

        }
    }




    render() {
        const {i18n, t} = this.props;
        var thisState = this.state;
        var settings = {
            customPaging: function(i) {
                return (
                    <a>
                        <img src={"/img/projects/"+thisState.projectImageFolder+"/"+i+".png"} />
                    </a>
                );
            },
            dots: true,
            infinite: true,
            arrows:false,
            fade:true,
            autoplay:true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,

        };

        if ( !this.state.loaded ) {
            return <div className="loader-people"><img className="infinityLoading" alt="Loading..." src={InfinityLoading}/></div>
        }

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('about-us.seo.title') ? t('about-us.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('about-us.seo.description') ? t('about-us.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('about-us.seo.title') ? t('about-us.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('about-us.seo.description') ? t('about-us.seo.description') : ""} />
                </Helmet>

                <div className="container content fade-in-website" id="projects-detail">
                    <div className={"row"}>
                    <div className={"col-md-6"}>
                        {this.state.projectName !== null && this.state.projectName !== "" && this.state.projectName !== undefined ?
                            <h1>
                                {this.state.projectName}
                            </h1>
                            : null}

                        {this.state.projectPopis !== null && this.state.projectPopis !== ""   && this.state.projectPopis !== undefined  ?
                            <div>
                                <h2>
                                    Popis:
                                </h2><br/>
                                <p>
                                    {this.state.projectPopis}
                                </p><br/>
                            </div>
                            : null}

                        {this.state.projectArchitekt !== null && this.state.projectArchitekt !== "" && this.state.projectArchitekt !== undefined ?
                            <div>
                                <h2>
                                    Architekt:
                                </h2><br/>
                                <p>
                                    {this.state.projectArchitekt}
                                </p><br/>
                            </div>
                            : null}

                        {this.state.projectRealizacniFirma !== null && this.state.projectRealizacniFirma !== "" && this.state.projectRealizacniFirma !== undefined ?
                            <div>
                                <h2>
                                    Realizační firma:
                                </h2><br/>
                                <p>
                                    {this.state.projectRealizacniFirma}
                                </p><br/>
                            </div>
                            : null}


                        {this.state.projektant !== null && this.state.projektant !== "" && this.state.projektant !== undefined ?
                            <div>
                                <h2>
                                    Projektant:
                                </h2><br/>
                                <p>
                                    {this.state.projektant}
                                </p><br/>
                            </div>
                            : null}

                        {this.state.investicniNaklady !== null && this.state.investicniNaklady !== "" && this.state.investicniNaklady !== undefined ?
                            <div>
                                <h2>
                                  Investiční náklady:
                                </h2><br/>
                                <p>
                                    {this.state.investicniNaklady}
                                </p><br/>
                            </div>
                            : null}

                        {this.state.typeProject === "inzenyrske-stavby" ?
                            <button className="back"><a href="/projekty/inzenyrske-stavby/">Zpět na seznam projektů</a></button>

                            :
                            <button className="back"><a href="/projekty/pozemni-stavby/">Zpět na seznam projektů</a></button>
                        }


                    </div>



                    <div className={"col-md-6"}>
                        <Slider {...settings}>

                            {this.state.projectImages.map(image => (
                                <div>
                                    {image}
                                </div>
                            ))}
                            {/*
                            <div>
                                <img src={"/img/projects/projekt1_a.jpeg"}/>
                            </div>
                            <div>
                                <img src={"/img/projects/projekt2_a.jpeg"}/>
                            </div>
                            <div>
                                <img src={"/img/projects/projekt3_a.jpeg"}/>
                            </div>
                            <div>
                                <img src={"/img/projects/projekt4_a.jpeg"}/>
                            </div>*/}
                        </Slider>
                    </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation()(ProjectsDetail);

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import { unregister } from './registerServiceWorker';

import './i18n';
import InfinityLoader from "./img/infinity.svg";
import { init as initApm } from '@elastic/apm-rum'

window.API = process.env.REACT_APP_API_URL;


var apm = initApm({

    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'sandt-mfcc-cz',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: 'https://apm.mfcc.cz/',

    // Set service version (required for sourcemap feature)
    serviceVersion: '1'
});


const Loader = () => (
    <div className="loaderMain"><img src={InfinityLoader} alt="Loading..."/></div>
);

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        <Home />
    </Suspense>,
    document.getElementById('root'));
unregister();
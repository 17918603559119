import React, { Component } from 'react';
import './App.css';
import $ from 'jquery';


import {Link} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import {withTranslation, Trans} from "react-i18next";

import {Helmet} from "react-helmet";
import personThumb from "./img/personThumb.png";

class Office extends Component {

    constructor (props){
        super(props);

        this.state = {
            div1showed: false,
            div2showed: false,
            div3showed: false
        };
    }


    hoverHandle(event) {

       // event.target.classList.add('active');
        if(event === 1){
            this.setState({
                div1showed : true
            });
        }
        if(event === 2){
            this.setState({
                div2showed : true
            });
        }
        if(event === 3){
            this.setState({
                div3showed : true
            });
        }

        if(event === 4){
            this.setState({
                div1showed : false,
                div2showed : false,
                div3showed : false

            });
        }

    }

    componentDidMount(){

    }



    render() {

      const { i18n, t } = this.props;

    return (
<div  >
    <Helmet>
        <title>{i18n.exists('office.seo.title') ? t('office.seo.title') : "Agile GE"}</title>
        <meta name="description" content={i18n.exists('office.seo.description') ? t('office.seo.description') : ""} />
        <meta property="og:title" content={i18n.exists('office.seo.title') ? t('office.seo.title') : "Agile GE"} />
        <meta property="og:description" content={i18n.exists('office.seo.description') ? t('office.seo.description') : ""} />
    </Helmet>
        <div className="container content fade-in-website" id="office"  >

      {/*      <h3>AGILE CONSULTING ENGINEERS …</h3>

*/}
            {/*<div className={"row"}>*/}
            {/*    <div className={"col-md-12"}>*/}
            {/*        <span>*/}
            {/*    Agile Consulting Engineers s.r.o. je progresivní pražská statická projekční kancelář poskytující služby v oblasti statiky a dynamiky konstrukcí, mostních a inženýrských staveb a geotechniky. Byla založena Janem Tomšů, Pavlem Roubalem a Petrem Tomášem s cílem sdružit experty ve svých oborech a vytvořit komplexní projekční firmu, která bude umět vyhovět zvyšujícím se nárokům na standardy 21. století, s důrazem na kvalitu návrhu a jeho prezentaci, efektivnost řešení, rychlost zpracování, a komunikaci a koordinaci s klientem a dalšími profesemi.*/}
            {/*    Klademe důraz na integrovaný systém práce, při kterém se snažíme od prvopočátku každého projektu o efektivní komunikaci a koordinaci s klientem, investorem nebo dalšími profesemi. Ke každému projektu přistupujeme individuálně a vnímáme jej jako celistvý proces, kdy se v každé jeho fázi od průzkumů, studie, přes stavební povolení až po realizaci snažíme o optimalizaci návrhu tak, aby bylo dosaženo kvalitního konečného výsledku.*/}
            {/*    V Agile využíváme nejmodernější výpočetní a grafické softwary a uplatňujeme naše zkušenosti z mezinárodních firem a projektů tak, abychom klientům dodali projektovou dokumentaci v minimálním možném čase a maximální možné kvalitě. Rádi se zapojujeme do projektů už v jejich konceptuální fázi, ve které můžeme ve spolupráci s architektem umožnit chytrá, inovativní a přímočará řešení pro komplikované a ambiciózní projekty.*/}
            {/*        </span><br/><br/><br/>*/}
            {/*        <h3>  Vedení společnosti</h3><br/>*/}
            {/*    </div>*/}

            {/*    <br/><br/><br/>*/}
            {/*</div>*/}


            {/*<div className={"row font-15 " + ( this.state.div1showed ? "active" : "")}  onMouseLeave={(e) => this.hoverHandle(4)} onMouseEnter={(e) => this.hoverHandle(1)}>*/}

            {/*    <div className={"col-md-3"} onMouseEnter={(e) => this.hoverHandle(1)}>*/}
            {/*        <img src={personThumb}/>*/}
            {/*    </div>*/}
            {/*    <div className={"col-md-9"} onMouseEnter={(e) => this.hoverHandle(1)}>*/}
            {/*        <h4 onMouseEnter={(e) => this.hoverHandle(1)}>Jan Tomšů, MSc & DIC CEng MIstructE</h4>*/}
            {/*       <span onMouseEnter={(e) => this.hoverHandle(1)}> Jan vystudoval bakalářský program v oboru Konstrukce a dopravní stavby na ČVUT v Praze a následně inženýrský program Structural Steel Design na Imperial College v Londýně. Pracoval 6 let v Londýně, nejprve v oceňované statické kanceláři Techniker Ltd a poté v progresivní kanceláři Heyne Tillett Steel, na významných projektech s prominentními architektonickými kancelářemi jako např. Foster + Partners, John Pawson Architects, AWW, Stanton Williams Architects, Eva Jiricna, DunnettCraven, Glenn Howels Architects a dalšími. V roce 2018 se vrátil do ČR a pracoval jako Project manager ve firmě Mott MacDonald CZ, se kterou dodnes spolupracuje v roli externího konzultanta. Jan má více než 10 let zkušeností zejména s projekty velkých rozsahů a jeho specialitou jsou inovativní řešení ze speciálních materiálů (sklo, CLT, UHPC). Je držitelem obecné mezinárodní autorizace CEng (Chartered Engineer) udělené britskou The Institution of Structural Engineers v Londýně a české autorizace ČKAIT v oboru v roce 2018 Statika a dynamika staveb.*/}
            {/*       </span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<br/><br/>*/}
            {/*<div  className={"row font-15 " + ( this.state.div2showed ? "active" : "")}  onMouseLeave={(e) => this.hoverHandle(4)} onMouseEnter={(e) => this.hoverHandle(2)}>*/}
            {/*    <div className={"col-md-9"} onMouseEnter={(e) => this.hoverHandle(2)}>*/}
            {/*        <h4 onMouseEnter={(e) => this.hoverHandle(2)}>Ing. Pavel Roubal</h4>*/}
            {/*        <span onMouseEnter={(e) => this.hoverHandle(2)}>Pavel má téměř 20 let zkušeností s navrhováním nosných konstrukcí pozemních staveb. Vystudoval inženýrský program v oboru Konstrukce a materiály na ČVUT v Praze a po studiu nastoupil do pražské statické kanceláře STA-CON s.r.o., ve které působil 14 let až do roku 2019 a z pozice konstruktéra se postupně vypracoval na samostatného projektanta – vedoucího týmu. Na začátku kariéry se převážně věnoval ocelovým a dřevěným konstrukcím, dnes považuje za svoje hlavní zaměření projekty rekonstrukcí zejména historických budov. V praxi se aktivně věnuje i navrhování monolitických železobetonových staveb. Pro odlehčení pracovní náplně se v rámci své profese zabývá i spoluprací s architekty a designéry na projektech, které přímo nesouvisí s návrhem nosných konstrukcí objektů, ale jsou jejich součástí, jako je atypický nábytek, výstavní expozice, schodiště apod.*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*    <div className={"col-md-3"} onMouseEnter={(e) => this.hoverHandle(2)}>*/}
            {/*        <img src={personThumb}/>*/}
            {/*    </div>*/}

            {/*</div>*/}
            {/*<br/><br/>*/}

            {/*<div  className={"row font-15 " + ( this.state.div3showed ? "active" : "")}  onMouseLeave={(e) => this.hoverHandle(4)}  onMouseEnter={(e) => this.hoverHandle(3)}>*/}
            {/*    <div className={"col-md-3"} onMouseEnter={(e) => this.hoverHandle(3)}>*/}
            {/*        <img src={personThumb} onMouseEnter={(e) => this.hoverHandle(3)}/>*/}
            {/*    </div>*/}
            {/*    <div className={"col-md-9"} onMouseEnter={(e) => this.hoverHandle(3)}>*/}
            {/*        <h4 onMouseEnter={(e) => this.hoverHandle(3)}> Ing. Petr Tomáš</h4>*/}
            {/*        <span onMouseEnter={(e) => this.hoverHandle(3)}>Petr je specialistou na inženýrské konstrukce a geotechniku (AI) s téměř 20 lety praxí, vede veškeré projekty dceřiné firmy Agile Geotechnics v tomto odvětví a je garantem inženýrsko-geologických průzkumů. V roce 2003 dokončil studium na stavební fakultě ČVUT v oboru Konstrukce a dopravní stavby se zaměřením na geotechniku (AI) a podzemní stavby. Již v době studií začal pracovat pro projekční kancelář Ingutis s.r.o., kde se podílel na projektech městské infrastruktury, jako jsou podzemní kolektory a ražené kanalizace. Dále se věnoval speciálnímu zakládání a geotechnickým konstrukcím. V roce 2010 nastoupil do společnosti Mott MacDonald CZ, kde pracoval na pozici Project & Design Manager na projektech jak v ČR tak v zahraničí. Je držitelem osvědčení Báňského projektanta a Závodního.*/}
            {/*        </span>*/}

            {/*    </div>*/}

            {/*</div>*/}







            <br/><br/><br/>
            <h3>
              Agile tým<br/><br/>
            </h3>

            <div className={"row"}>
                <div className={"col-md-4"}>


                    <h4>Ing. Aleš Menšík</h4>
                    <span>mosty a inženýrské konstrukce (AI)</span><br/>
                    <span>E-mail: <a href={"mailto:ales.mensik@agile-ge.cz"}>ales.mensik@agile-ge.cz</a></span><br/>
                    <span>Telefon: <a href={"tel:737754754"}>737 754 754</a></span><br/>

                </div>
                <div className={"col-md-4"}>
                    <h4>Tomáš Kaláb</h4>
                    <span>projektant inženýrské <br/>a pozemní stavby</span><br/>
                    <span>E-mail: <a href={"mailto:tomas.kalab@agile-ge.cz"}>tomas.kalab@agile-ge.cz</a></span><br/>


                </div>


                <div className={"col-md-4"}>


                    <h4>Bc. Andrei Perkhaim</h4>
                    <span>junior projektant inženýrské <br/>a pozemní stavby</span><br/>
                    <span>E-mail: <a href={"mailto:andrei.perkhaim@agile-ge.cz"}>andrei.perkhaim@agile-ge.cz</a></span><br/>

                </div>
            </div>


           <br/><br/><br/>


            {/*<div>*/}
            {/*    <p>Agile dále spolupracuje s množstvím externích spolupracovníků, se kterými flexibilně skládáme pracovní týmy pro projekty největších rozsahů.</p>*/}
            {/*</div>*/}


        </div>

</div>
    );
  }
}

export default withTranslation()(Office);

import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';
// or less ideally

import {withTranslation, Trans} from "react-i18next";
import {Link} from "react-router-dom";

import {Helmet} from "react-helmet";
import Slider from "react-slick";
import mainSlideImg from "./img/mainSlider.jpg";


class Services extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded : false,
            services : [],
            expertise : []
        }
    }


    componentDidMount() {
        $(window).scrollTop(0);


    }


    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('services.seo.title') ? t('services.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('services.seo.description') ? t('services.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('services.seo.title') ? t('services.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('services.seo.description') ? t('services.seo.description') : ""} />
                </Helmet>

                <div className="container content fade-in-website services" id="services">

                    <h3>Inženýrská geologie</h3>
                    <br/>
                    Inženýrská geologie je aplikovaný obor, který poskytuje projektantům staveb informace o složení a
                    vlastnostech podloží konkrétního území, na kterém je projektována stavba (domy, mosty, silnice,
                    průmyslové stavby, přehrady... ) nebo kde je potřeba zhodnotit nějaké geologické riziko pro
                    společnost (sesuvy, řícení skal, propady terénu kvůli vlivu těžby a mnohé další.).<br/><br/>

                    Inženýrskogeologický průzkum je nezbytnou součástí přípravy projektů všech staveb, ať se jedná o
                    rodinné domy, bytové domy, mostní stavby, vodohospodářské objekty apod.
                    <br/><br/>
                    V rámci naší společnosti zajišťujeme:   <br/>   <br/>
                    <ul>
                        <li>Orientační IG průzkum (rešerše) – podklad pro zpracování pro DUR</li>
                        <li>Podrobný IG průzkum – podklad pro zpracování pro DSP/PDPS</li>
                        <li>Doplňkový průzkum – zpřesnění výsledků IG průzkumu z předchozích etap</li>
                    </ul>


                    Dále zajišťujeme:   <br/>   <br/>
                    <ul>
                        <li>HG průzkumy</li>
                        <li>Pedologické průzkumy</li>
                        <li>Radonový index pozemku</li>
                    </ul>

                    <br/>
                    <h3>Diagnostika stavebních konstrukcí, stavebně technický průzkum</h3>
                    <br/>
                    Z naší praxe víme, že v rámci rekonstrukce objektu potřebujeme znát kvalitu stávajícího materiálu,
                    jeho vlastnosti, případně další parametry. Toto zajistí diagnostika stavebních konstrukcí případně
                    stavebně technický průzkum. Níže uvádíme možnosti diagnostiky:   <br/>   <br/>
                    <ul>
                        <li>Ověření stávajících základových konstrukcí</li>
                        <li>Stanovení únosnosti základové spáry</li>
                        <li>Pevnost zdiva</li>
                        <li>Průzkum vodorovných nosných konstrukcí</li>
                        <li>Stanovení skladby konstrukcí</li>
                        <li>Průzkum svislých nosných i nenosných konstrukcí, stanovení skladby konstrukcí</li>
                        <li>Mykologický průzkum</li>
                    </ul>

                    <br/>
                    <h3>Mosty a inženýrské konstrukce</h3>
                    <br/>

                    Naše společnost zpracovává projektovou dokumentaci pro:   <br/>   <br/>
                    <ul>
                        <li>Mostní konstrukce - propustky, lávky, mosty, produktovody</li>
                        <li>Opěrné a zárubní zdi</li>
                        <li>Protihlukové stěny</li>
                    </ul>



                    Jedná se tedy o kompletní projektovou dokumentaci novostaveb i rekonstrukce od studií až po
                    dokumentaci skutečného provedení stavby (mostního objektu)   <br/>   <br/>
                    <ul>
                        <li>Technicko-ekonomické studie</li>
                        <li>Studie proveditelnosti</li>
                        <li>DÚR - dokumentace pro územní rozhodnutí</li>
                        <li>DSP - dokumentace pro stavební povolení</li>

                        <li>PDPS - projektová dokumentace pro provádění stavby</li>
                        <li>RDS - realizační dokumentace stavby</li>
                        <li>DSPS - dokumentace skutečného provedení stavby</li>
                        <li>VTD - výrobně technické dokumentace</li>
                        <li>Statické posouzení mostních objektů a inženýrských konstrukcí - přepočty zatížitelnosti
                            mostních objektů</li>
                    </ul>



                    Součástí naší práce je také konzultační činnost, kde:   <br/>   <br/>
                    <ul>
                        <li>Optimalizujeme navrhované řešení</li>
                        <li>Revidujeme projektovou dokumentací</li>
                        <li>Vykonáváme autorský dozor</li>
                    </ul>




                    Dále provádíme mostní prohlídky silničních mostů a to v rozsahu:   <br/>   <br/>
                    <ul>
                        <li>1.HPM 1. hlavní prohlídka mostu</li>
                        <li>HPM hlavní prohlídka mostu</li>
                        <li>MPM mimořádná prohlídka mostu</li>
                        <li>BPM běžná prohlídka mostu</li>
                    </ul>


                    Pro tyto činnosti disponujeme autorizací v oboru mosty a inženýrské konstrukce.
                    <br/>
                    Pro výpočty používáme SW Scia Engineering.
                    <br/><br/>
                    <h3>Vodohospodářské stavby</h3>
                    <br/>

                    Spolupracujeme s společnostmi projektující ČOV (čistírny odpadních vod), pro které zajišťujeme
                    dokumentaci nosných konstrukcí jako jsou nádrže, čerpací stanice, základy pro ocelové nádrže, nosné
                    konstrukce potrubních mostů apod.
                    <br/><br/>
                    <h3>Tunely a geotechnika</h3>
                    <br/>


                    Máme zkušenosti z projektování tunelových staveb, stavebních jam a opěrných zdí v rámci silničního
                    a železničního stavitelství.
                    <br/><br/>

                    Navrhujeme a řešíme pažící konstrukce všech možných typů (záporové pažení, štětovnicové stěny).
                    Navrhujeme milánské podzemní stěny, pilotové stěny, hřebíkované konstrukce apod.
                    <br/><br/>
                    Projektujeme armované konstrukce, opěrné a zárubní zdi, štoly a tunely nejen pro vodohospodářské
                    stavby, šachty, protlaky a založení všech typů konstrukcí.
                    <br/><br/>

                    Počítáme v programu GEO 5 2023 CS.
                    <br/><br/>

                    Vlastníme oprávnění autorizovaný inženýr pro geotechniku a máme oprávnění báňského projektanta
                    a závodního v ČR dle zákona č. 61/1988.
                    <br/><br/>
                    <h3>Pozemní stavby</h3>
                    <br/>

                    Projekty pozemních staveb zajišťuje naše sesterská společnost Agile Consulting Engineers s.r.o.
                    <a href={"www.agile-ce.cz"} target={"_blank"}>www.agile-ce.cz</a>


                </div>

            </div>
        );
    }
}

export default withTranslation()(Services);

import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';


import {withTranslation, Trans} from "react-i18next";

import {Helmet} from "react-helmet";




class Projection extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            officeManagers : [],
            loaded : false,
            formSending : false,
            formSentSuccessfully : false,
            formError : null,
            formInvalidFields : {}
        };


    }

    componentDidMount() {

    }


    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('contact.seo.title') ? t('contact.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('contact.seo.description') ? t('contact.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('contact.seo.title') ? t('contact.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('contact.seo.description') ? t('contact.seo.description') : ""} />
                </Helmet>
                <div className="container content fade-in-website contact" id="contact">

                </div>

            </div>
        );
    }
}

export default withTranslation()(Projection);

import React, { Component } from 'react';

import './App.css';

import {withTranslation, Trans} from "react-i18next";

class GDPR extends Component {
  render() {
      const {t } = this.props;
      console.log(this.props.content);
    return (
            <div>



                <div className="container content fade-in-website gdpr" id="gdpr">

                    <h1 dangerouslySetInnerHTML={{__html: t("gdpr.headline")}} />

                    <div className="row">
                        <div className="col-md-12" >
                            <span dangerouslySetInnerHTML={{__html: t("gdpr.content")}} />
                        </div>
                    </div>
                </div>




            </div>
    );
  }
}

export default withTranslation()(GDPR);

import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';


import {withTranslation, Trans} from "react-i18next";

import {Helmet} from "react-helmet";

class Clients extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            officeManagers : [],
            loaded : false,
            formSending : false,
            formSentSuccessfully : false,
            formError : null,
            formInvalidFields : {}
        };

        this.onSubmit = this.onSubmit.bind(this);

        t('contact.form.validation.empty');
        t('contact.form.validation.invalid_email');
        t('contact.form.validation.gdpr-invalid');
    }

    componentDidMount() {

    }

    onSubmit(event) {
        event.preventDefault();
        const { i18n } = this.props;
        const self = this;
        self.setState({
            formSending : true
        });
        let $form = $(event.target);

        $.ajax({
            'method' : 'post',
            'data' : $form.serialize(),
            'url' : window.API + '/contact/send-message?locale=' + i18n.language,
            'success' : function(data) {

                if(data.status === 'success') {
                    self.setState({
                        formSending : false,
                        formSentSuccessfully : true
                    });
                    $form[0].reset();
                    setTimeout(function() { self.setState({ formSentSuccessfully : false }); }, 10000);
                } else {
                    self.setState({
                        formSending : false,
                        formSentSuccessfully : false,
                        formError : data.message,
                        formInvalidFields : data.errors
                    });
                }
            }
        });

        return false;
    }


    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('clients.seo.title') ? t('clients.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('clients.seo.description') ? t('clients.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('clients.seo.title') ? t('clients.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('clients.seo.description') ? t('clients.seo.description') : ""} />
                </Helmet>

                <div className="container content fade-in-website clients" id="clients">

                        <div className="col-md-3">
                            <a href="https://www.moatelier.eu/"  target={"_blank"} href="https://www.moatelier.eu">
                                <img src="/img/clients/MO_logo_cz_800.jpg"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="http://www.petrjanda.com/"  target={"_blank"} href="http://www.petrjanda.com/">
                                <img src="/img/clients/janda.png"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="https://www.atpatelier.cz/"  target={"_blank"} href="https://www.atpatelier.cz/">
                                <img src="/img/clients/atp-atelier-logo.jpg"/>
                            </a>
                        </div>



                        <div className="col-md-3">
                            <a href="http://lostarch.cz/"  target={"_blank"} href="http://lostarch.cz/">
                                <img src="/img/clients/LA_logo.jpg"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="https://www.sglprojekt.cz/"  target={"_blank"} href="https://www.sglprojekt.cz/">
                                <img src="/img/clients/sgl.jpg"/>
                            </a>
                        </div>



                        <div className="col-md-3">
                            <a href="https://atelier-santavy.cz/"  target={"_blank"} href="https://atelier-santavy.cz/">
                                <img src="/img/clients/TS.jpg"/>
                            </a>
                        </div>



                        <div className="col-md-3">
                            <a href="http://www.mca-atelier.com/"  target={"_blank"} href="http://www.mca-atelier.com/">
                                <img src="/img/clients/mca.jpg"/>
                            </a>
                        </div>



                        <div className="col-md-3">
                            <a href="http://olafstudio.cz/"  target={"_blank"} href="http://olafstudio.cz/">
                                <img src="/img/clients/olaf.png"/>
                            </a>
                        </div>





                        <div className="col-md-3">
                            <a href="https://www.famarchitekti.cz/"  target={"_blank"} href="https://www.famarchitekti.cz/">
                                <img src="/img/clients/fam.png"/>
                            </a>
                        </div>



                        <div className="col-md-3">
                            <a href="http://www.tektek.cz/"  target={"_blank"} href="http://www.tektek.cz/">
                                <img src="/img/clients/tektek.png"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="http://collarch.cz/"  target={"_blank"} href="http://collarch.cz/">
                                <img src="/img/clients/collarch.jpg"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="https://www.abtsmolen.cz/"  target={"_blank"} href="https://www.abtsmolen.cz/">
                                <img src="/img/clients/abtsmolen.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <img src="/img/clients/tomsuaklimes.jpg"/>

                        </div>

                        <div className="col-md-3">
                            <a href="https://www.sokoban.cz/"  target={"_blank"} href="https://www.sokoban.cz/">
                                <img src="/img/clients/sokoban.jpg"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="https://www.arkos.cz/"  target={"_blank"} href="https://www.arkos.cz/">
                                <img src="/img/clients/arkos.jpg"/>
                            </a>
                        </div>



                        <div className="col-md-3">
                            <a href="https://www.ateliertvar.cz/"  target={"_blank"} href="https://www.ateliertvar.cz/">
                                <img src="/img/clients/tvar.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.majoarchitekti.cz/" target={"_blank"} href="https://www.majoarchitekti.cz/">
                                <img src="/img/clients/majo.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="http://kavanova.com/" target={"_blank"} href="http://kavanova.com/">
                                <img src="/img/clients/kavanova.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="http://mbprojekt.cz/" target={"_blank"} href="http://mbprojekt.cz/">
                                <img src="/img/clients/MB_logo.bmp"/>
                            </a>
                        </div>



                        <div className="col-md-3">
                            <a href="http://www.mvprojekt.cz/" target={"_blank"} href="http://www.mvprojekt.cz/">
                                <img src="/img/clients/mvprojekt.jpg"/>
                            </a>
                        </div>



                        <div className="col-md-3">
                            <a href="https://www.sudop.cz/cs" target={"_blank"} href="https://www.sudop.cz/cs">
                                <img src="/img/clients/sudop.png"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="https://www.metroprojekt.cz/" target={"_blank"} href="https://www.metroprojekt.cz/">
                                <img src="/img/clients/metroprojekt.png"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="https://www.mottmac.com/czech-republic" target={"_blank"} href="https://www.mottmac.com/czech-republic">
                                <img src="/img/clients/mott.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.afrycz.cz/" target={"_blank"} href="https://www.afrycz.cz/">
                                <img src="/img/clients/afry.png"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.promika.cz/" target={"_blank"} href="https://www.promika.cz/">
                                <img src="/img/clients/promika.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.4roads.cz/" target={"_blank"} href="https://www.4roads.cz/">
                                <img src="/img/clients/4roads.jpg"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="https://www.valbek.eu/cs/" target={"_blank"} href="https://www.valbek.eu/cs/">
                                <img src="/img/clients/valbek.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.obermeyer.cz/" target={"_blank"} href="https://www.obermeyer.cz/">
                                <img src="/img/clients/helika.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.sps-sro.cz/" target={"_blank"} href="https://www.sps-sro.cz/">
                                <img src="/img/clients/sps.png"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.sps-sn.cz/" target={"_blank"} href="https://www.sps-sn.cz/">
                                <img src="/img/clients/sps-sn.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.betonserver.cz/dolezal-praha" target={"_blank"} href="https://www.betonserver.cz/dolezal-praha">
                                <img src="/img/clients/dolezal.jpeg"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="http://www.kvs-stavebni.cz/" target={"_blank"} href="http://www.kvs-stavebni.cz/">
                                <img src="/img/clients/kvs.png"/>
                            </a>
                        </div>


                        <div className="col-md-3">
                            <a href="https://www.statical.eu/cs/" target={"_blank"} href="https://www.statical.eu/cs/">
                                <img src="/img/clients/statical.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.trepart.cz/" target={"_blank"} href="https://www.trepart.cz/">
                                <img src="/img/clients/trepart.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://sveastavby.cz/" target={"_blank"} href="https://sveastavby.cz/">
                                <img src="/img/clients/svea.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://gartal.cz/cs?gclid=Cj0KCQiAq7COBhC2ARIsANsPATGb_wRy7FY-zxsr1NSqSLxdbhzUDxeqMqwNJ4excv3GsiS9Jy7nbGQaAkBKEALw_wcB" target={"_blank"} href="https://gartal.cz/cs?gclid=Cj0KCQiAq7COBhC2ARIsANsPATGb_wRy7FY-zxsr1NSqSLxdbhzUDxeqMqwNJ4excv3GsiS9Jy7nbGQaAkBKEALw_wcB">
                                <img src="/img/clients/gartal.png"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="http://www.livingsolution.cz/" target={"_blank"} href="http://www.livingsolution.cz/">
                                <img src="/img/clients/living.png"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.prg.aero/" target={"_blank"} href="https://www.prg.aero/">
                                <img src="/img/clients/letiste.jpg"/>
                            </a>
                        </div>

                        <div className="col-md-3">
                            <a href="https://www.praha14.cz/" target={"_blank"} href="https://www.praha14.cz/">
                                <img src="/img/clients/praha14.jpg"/>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="https://www.praha9.cz/" target={"_blank"} href="https://www.praha9.cz/">
                                <img src="/img/clients/praha9.jpg"/>
                            </a>
                        </div>
       {/*                 <div className="div" style={{width:"100%",textAlign:"right"}}>

                             <img style={{   maxHeight:"unset", width: "150px", height: "auto",  marginTop: "60px"}} src="/img/dalsi.jpg"/>

                        </div>*/}

                </div>
            </div>
        );
    }
}

export default withTranslation()(Clients);

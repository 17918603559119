export const ProjectData =  {

    "inzenyrske_stavby" : [
        {
            "name" :  "Bytový dům Ocelářská – ODETA TERAS",
            "image_folder" :"bytovy_dum_ocelarska",
            "id": "bytovy-dum-ocelarska-odeta-teras",
            "imageCount": 6,
            "architekt" : "MLAA + Tomšů a Klimeš s.r.o.",
            "investicni_naklady" : "cca 120 mil. Kč",
            "kratky_popis" : "Projekt nosné konstrukce osmipodlažního bytového domu se suterénem a s vibračně odizolovanou spodní stavbou",
            "popis" : " Projekt pro územní řízení a stavební povolení osmipodlažního\n" +
                "bytového domu s podzemními garážemi v proluce mezi stávajícími\n" +
                "objekty. Konstrukce je řešena jako ŽB stěnový systém založený na\n" +
                "pilotách s dvojitou základovou deskou a mezilehlou vrstvou vibrační\n" +
                "izolace k odstranění vlivu vibrací způsobených provozem metra\n" +
                "v sousedícím tunelu stanice metra Českomoravská.\n" +
                "Stavební povolení uděleno, probíhají práce na Dokumentaci pro\n" +
                "provádění stavby.",
        },
        {
            "name" :  "Rekonstrukce Vozovny Slovany – Plzeň",
            "image_folder" :"vozovany_slovany",
            "id": "rekonstrukce-vozovny-slovany-plzen",
            "imageCount": 3,
            "architekt" : " Metroprojekt a.s. + APS Projekt Praha s.r.o. ",
            "investicni_naklady" : "cca 2,1 mld Kč",
            "kratky_popis" : "Projekt nosných ŽB konstrukcí nových objektů v rámci projektu rekonstrukce stávající tramvajové vozovny",
            "popis" : "Agile GE v rámci projektu pro stavební povolení a následně projektu " +
                "pro provádění stavby zpracoval návrh nosných ŽB konstrukcí třípodlažní " +
                "administrativní budovy a základových konstrukcí a profilovaných podlahových " +
                "desek ocelových hal vozovny. Administrativní budova je řešena jako ŽB skelet " +
                "se stabilitními stěnami a dvěma částečnými suterény. Profilovaná podlahová deska " +
                "haly oprav a údržby tramvají je řešena jako ŽB s komplexním tvarem částečně suterénních konstrukcí kanálů, " +
                "jímek, soustruhu apod. Podlahové desky hal odstavů tramvají jsou řešeny jako drátkobetonové.",
        },
        {
            "name" :  "Bytový dům Africká",
            "image_folder" :"bytovy_dum_africka",
            "architekt" : "OLAF studio s.r.o",
            "id": "bytovy-dum-africka",
            "imageCount": 5,
            "investicni_naklady" : "nejsou známé",
            "kratky_popis" : "Projekt nosné konstrukce bytového domu včetně zajištění stavební jámy",
            "popis" : "Projekt pro stavební povolení novostavby bytového domu." +
                " Konstrukce objektu je kompletně monolitická železobetonová – stěnový systém. " +
                "Objekt má šest nadzemních a jedno podzemní podlaží. Polohou je dům situován ve svahu, " +
                "úroveň 1. NP je na úrovni ul. Africká. Projekt pro provedení stavby je v přípravě.",
        },
        {
            "name" :  "Kobky Rašínovo nábřeží a Hořejší nábřeží",
            "image_folder" :"kobky_rasinovo_nabrezi",
            "imageCount": 5,
            "id": "kobky-rasinovo-nabrezi-a-horejsi-nabrezi",
            "architekt" : " Ing. arch. MgA. Petr Janda ",
            "investicni_naklady" : "neurčeny",
            "kratky_popis" : "Technická podpora při realizaci betonových konstrukcí",
            "popis" : "Agile GE se podílel na realizaci obnovy kobek, kde jsme pro architekta Petra Jandu zpracovávali technickou podporu při realizaci monolitických a ocelových konstrukcí. Projekt začal zpracovávat Ing. Pavel Roubal v rámci předchozího zaměstnaneckého poměru a následně si ho přenesl do Agile GE, kde byl zdárně dokončen. Po dobu výstavby jsme prováděli technickou podporu i jednotlivým dodavatelům.",
        },
        {
            "name" :  "Národní Muzeum – Expozice „Lidé“",
            "image_folder" :"narodni_muzeum_lide",
            "id": "narodni-muzeum-expozice-lide",
            "imageCount": 9,
            "architekt" : " Ing. arch. MgA. Petr Janda ",
            "investicni_naklady" : "neurčeny",
            "kratky_popis" : "Projekt nosných konstrukcí organicky tvarovaných objektů v 10 místnostech expozic Národního Muzea",
            "popis" : "V rámci projektové dokumentace pro provádění jsme pro architekta Petra Jandu zpracovali vysoce komplikované návrhy nosných konstrukcí organicky tvarovaných objektů v 10 místnostech 2.NP budovy Národního Muzea. Nosné konstrukce jsou navržené z oceli, skla a hliníkových voštinových desek, na kterých byly v rámci výpočtů provedeny zkoušky v laboratořích Univerzity Tomáše Bati ve Zlíně k určení správných parametrů pro tvorbu výpočetních 3D modelů. Projekt bude po vysoutěžení dodavatele pokračovat zpracováním podrobné výrobní dokumentace",
        },
        {
            "name" :  "Základní škola Litvínovská 500 - nástavba",
            "image_folder" :"zakladni_skola_litvinovska",
            "id": "zakladni-skola-litvinovska",
            "imageCount": 5,
            "architekt" : " Ing. arch. Jaroslav Tomšů ",
            "investicni_naklady" : "neurčeny",
            "kratky_popis" : "Projekt nosné konstrukce nástavby stávajícího čtyřpodlažního objektu",
            "popis" : "Projekt pro územní rozhodnutí a stavební povolení pro\n" +
                "městskou část Praha 9 řeší Agile GE jako generální projektant.\n" +
                "Navrhovaný objekt je nástavbou na stávající objekt budovy ZŠ\n" +
                "Litvínovská 500, ve kterém budou umístěny nové učebny a\n" +
                "hygienická zařízení. Navrhovaná nástavba je jednopodlažní\n" +
                "konstrukce obdélníkového půdorysu tvořící nové 4.NP na stávajícím\n" +
                "čtyřpodlažním objektu. Součástí předběžného návrhu je i rozšíření\n" +
                "spojovacího krčku mezi budovami v úrovni 1.NP, požární schodiště\n" +
                "při JV fasádě a výtah s rampou při JZ fasádě.\n" +
                "Navržená konstrukce nástavby je ocelový rám s lehkým opláštěním.",
        },
        {
            "name" :  "Základní škola Formanská",
            "image_folder" :"zakladni_skola_formanska",
            "architekt" : " OLAF studio s.r.o",
            "id": "zakladni-skola-formanska",
            "imageCount": 6,
            "investicni_naklady" : "nejsou známé",
            "kratky_popis" : "Projekt studie nosné konstrukce objektu základní školy.",
            "popis" : "Studie proveditelnosti nosné konstrukce základní školy byla provedena na 3D modelu. Jednat by se mělo o kompletně monolitickou železobetonovou konstrukci v kombinaci sloupů a stěn. Nad sportovní halou by se měl využít monolitický kazetový strop. Na střeše sportovní haly se plánu provedení venkovního hřiště po obvodě s ochranou ocelovou konstrukcí. Provozně dispoziční řešení školní budovy se skládá ze sedmi vzájemně provázaných a prolnutých sekcí a úseků, které jsou umístěny v hmotě jedné budovy. Tyto sekce jsou sdruženy do tří velkých bloků, které vzájemně propojené připomínají tvar podkovy, nebo již zmíněný tvar písmena „C“.",
        },
        {
            "name" :  "Základní škola Litvínovská 600 - přístavba",
            "image_folder" :"zakladni_skola_litvinovska_600",
            "imageCount": 4,
            "id": "zakladni-skola-litvinovska-600",
            "architekt" : " Ing. arch. Jaroslav Tomšů ",
            "investicni_naklady" : "neurčeny",
            "kratky_popis" : "Projekt nosné konstrukce přístavby gymnastického sálu ke stávajícímu objektu tělocvičny",
            "popis" : "Projekt pro územní rozhodnutí a stavební povolení pro městskou část Praha 9. Navrhovaný objekt je přístavbou ke stávajícímu objektu ZŠ Litvínovská 600, ve kterém budou umístěny nové tělocvičny, nářaďovny, šatny a hygienická zařízení. Navrhovaný objekt je čtyřpodlažní obdélníkového půdorysu s jedním podzemním a třemi nadzemními podlažími, přiléhající ze severovýchodní strany na stávající objekt tělocvičny. Objekt přístavby bude propojen s hlavní budovou školy částečně podzemním koridorem přiléhajícím z jihovýchodní strany ke stávajícímu objektu tělocvičny. Konstrukční systém přístavby je ocelová rámová konstrukce s lehkým opláštěním",
        },
        {
            "name" :  "Polyfunkční dům v Pitkovicích ",
            "image_folder" :"polyfunkcni_dum_v_pitkovicich",
            "architekt" : " MO Atelier s.r.o.",
            "imageCount": 3,
            "id": "polyfunkcni-dum-v-pitkovicich",
            "investicni_naklady" : "cca 89 mil Kč",
            "kratky_popis" : "Projekt nosné konstrukce objektu a opěrných zdí",
            "popis" : "Přízemní stavba krytá zelenou střechou vyrůstá z lehce svažité\n" +
                "pláně. Základním parametrem stavebního řešení je rychlost\n" +
                "výstavby. Z tohoto důvodu je zvolená kombinace ocelové konstrukce\n" +
                "z válcovaných profilů a systému tenkostěnných pozinkovaných\n" +
                "profilů. Objekt bude založen na monolitické železobetonové desce. ",
        },
        {
            "name" :  "Rodinný dům Husova – Poděbrady",
            "image_folder" :"rodinny_dum_husova",
            "imageCount": 3,
            "id": "rodinny-dum-husova",
            "architekt" : "Ing. arch. Hana Kaňkovská ",
            "investicni_naklady" : "cca 15 mil. Kč",
            "kratky_popis" : "Projekt nosné konstrukce zajímavého objektu rodinného domu.  ",
            "popis" : "Projekt nadstandardního rodinného domu pro stavební povolení a provedení stavby. Konstrukce objektu je navržená jako monolitická železobetonová konstrukce. Pergola nad stáním pro osobní vozidla je ocelová. Z důvodu složitých základových poměrů je objekt založen na pilotách.",
        },
        {
            "name" :  "Viladomy U Nádraží, Klánovice ",
            "image_folder" :"viladomy_u_nadrazi_klanovice",
            "id": "viladomy-u-nadrazi-klanovice",
            "imageCount": 7,
            "architekt" : "Poka-yoke architekti s.r.o.",
            "projektant" : "ARKOS s.r.o. ",
            "investicni_naklady" : "cca 15 mil. Kč",
            "kratky_popis" : "Projekt konstrukční části menšího bytového domu. Objekt je proveden v kombinaci monolitického železobetonu a cihelného zdiva. ",
            "popis" : "Stavebním záměrem na předmětné parcele je umístění dvou\n" +
                "viladomů výhradně s byty s celkovou zastavěnou plochou 601,40 m2\n" +
                ", což\n" +
                "odpovídá zastavěnosti parcely v ploše 23,16 %. Celková koncepce\n" +
                "umístění domů na parcele i jejich výšek zohledňuje typické umístění\n" +
                "staveb v bezprostředním okolí.\n" +
                "Konstrukčně se jedná o monolitický železobeton v kombinaci se zděným\n" +
                "systémem. ",
        },
        {
            "name" :  "Orloj – filmová kulisa",
            "image_folder" :"orloj_filmova_kulisa",
            "id": "orloj-filmova-kulisa",
            "imageCount": 6,
            "architekt" : "Ing. arch. Petr Bouška ",
            "investicni_naklady" : "4 mil Kč ",
            "kratky_popis" : "",
            "popis" : "V rámci naší profese spolupracujeme s filmaři a vytváříme pro ně technickou podporu při realizaci složitých kulis. Ačkoliv se to na první pohled nemusí zdát, jedná se často o regulérní, avšak dočasné stavby, které by obstály i pro běžné užívání. Pro natáčení nejmenovaného seriálu v prostorách filmových ateliéru v Letňanech jsme zpracovali projekt nosné ocelové a dřevěné konstrukce 20 m vysokého Orloje.",
        },
        {
            "name" :  "Demolice budovy Transgas – Vinohradská 8",
            "image_folder" :"demolice_budovy_transgas",
            "id": "demolice-budovy-transgas",
            "imageCount": 5,
            "architekt" : "Ing. arch. Petr Bouška ",
            "realizacni_firma": "TREPART s.r.o.",
            "investicni_naklady" : "neurčeny",
            "kratky_popis" : "",
            "popis" : "Agile GE se podílí na demolici brutalistního komplexu\n" +
                "Transgasu na Vinohradské třídě. V rámci spolupráce s firmou\n" +
                "provádějící demolici zajišťujeme technickou podporu a návrh\n" +
                "zajišťovacích konstrukcí, aby bylo možné bezpečně objekt rozebrat.\n" +
                "V rámci demolice je třeba navrhovat konstrukce, které dočasně\n" +
                "objekt podpírají, případně posuzovat stávající konstrukce, zda je\n" +
                "možné je zatížit demoliční technikou. Dále byl proveden návrh\n" +
                "základů dvou věžových jeřábů a dočasných základů automobilových\n" +
                "jeřábů LTM 1350.",
        },
        {
            "name" :  "Demolice Vozovny Hloubětín",
            "image_folder" :"demolice_vozovny_hloubetin",
            "id": "demolice-vozovny-hloubetin",
            "imageCount": 3,
            "projektant" : " Metroprojekt Praha a.s. ",
            "realizacni_firma": "TREPART s.r.o.",
            "investicni_naklady" : "neurčeny",
            "kratky_popis" : "",
            "popis" : "Agile GE se podílí na demolici konstrukce Vozovny Hloubětín, kde pro demoliční firmu provedla návrh ocelových a dřevěných podpůrných konstrukcí. Tyto konstrukce následně zajišťovaly stabilitu konstrukcí při odstřelu střešních skořepin. Dále jsme poskytovali technickou podporu při demoličních pracích.",
        },
        {
            "name" :  "Resort Třešňovka, Mladá Boleslav – Podlázky",
            "image_folder" :"resort_tresnovka",
            "imageCount": 6,
            "id": "resort-tresnovka",
            "projektant" : "MO Atelier s.r.o.",
            "investicni_naklady" : "40 mil. Kč",
            "kratky_popis" : "",
            "popis" : "Jedná se o novostavbu bytového domu, která se nachází na svažitém terénu s rozdílem výšek kolem 2,5m. Navrhovaný objekt má jedno podzemní a 3 nadzemní patra. Podzemní patro je využíváno pro bydlení a umístění technických a skladovacích místností. Nadzemní patra jsou využívána výhradně k obytnému účelu. Půdorysný tvar připomíná nepravidelný lichoběžník s největšími rozměry přibližně 13,5x33,4 m. Poslední patro je půdorysně uskočené ze všech stran vně do objektu. Výška objektu po atiku je 13,6 m. Objekt je zakončen plochou střechou s atikou. Konstrukčně je objekt řešený jako monolitický železobeton v kombinaci se zdivem v posledním patře.",
        },
        {
            "name" :  "Mostní a inženýrské konstrukce",
            "image_folder" :"demolice_vozovny_hloubetin",
            "imageCount": 3,
            "id": "demolice-vozovny-hloubetin",
            "kratky_popis" : "",
            "popis" : "V Agile GE se také zabýváme návrhy mostních a inženýrských\n" +
                "konstrukcí, jako jsou silniční a železniční mosty, lávky pro pěší,\n" +
                "opěrné stěny, propustky, ražené kanalizace, tunely a podobně.\n" +
                "Jako externí konzultanti pro mostní a inženýrské konstrukce\n" +
                "figurujeme ve firmách Mott MacDonald CZ s.r.o. a AF Cityplan a.s. a\n" +
                "dále spolupracujeme s firmami Atelier Promika s.r.o., Advisia s.r.o.,\n" +
                "4Roads s.r.o., MV Projekt s.r.o.",
        },
    ]

};

export const ReferencesData =  {

    "reference" : [
        {
            "name" :  "PVA Praha - výstavní hala 1",
            "image_folder" :"pva-praha-vystavni-hala-1",
            "id": "pva-praha-vystavni-hala-1",
            "imageCount": 0,
            "objednatel" : "PVA EXPO a.s.",
            "investicni_naklady" : "",
            "kratky_popis" : "",
            "popis" : "Podrobný inženýrskogeologický průzkum pro založení nové haly",
        },
        {
            "name" :  "Novostavba administrativního, obchodního a logistického centra Maldá Boleslav - Řepov",
            "image_folder" :"mlada-boleslav-centrum",
            "id": "mlada-boleslav-centrum",
            "imageCount": 0,
            "objednatel" : "MO Atelier s.r.o.",
            "investicni_naklady" : "",
            "kratky_popis" : "",
            "popis" : "Inženýrskogeologický a hydrogeologický průzkum",
        },
        {
            "name" :  "Novostavba bytového domu na pozemcích parc. č. 320 a 321, k.ú. Ďáblice",
            "image_folder" :"novostavba-dablice",
            "id": "novostavba-dablice",
            "imageCount": 0,
            "objednatel" : "MO Atelier s.r.o.",
            "investicni_naklady" : "",
            "kratky_popis" : "",
            "popis" : "Inženýrskogeologický, hydrogeologický průzkum a radonový průzkum",
        },
        {
            "name" :  "Stavební úpravy domu č.p. 107, Malešické náměstí 8 v Praze 10 - Malešicích",
            "image_folder" :"malesicke-namesti",
            "id": "malesicke-namesti",
            "imageCount": 0,
            "objednatel" : "Agile Consulting Engineers s.r.o.",
            "investicni_naklady" : "",
            "kratky_popis" : "",
            "popis" : "Geotechnický a stavebně - technický průzkum",
        },
        {
            "name" :  "Rezidence Helénská, č. parc. 2260 a 2261 - Rekonstrukce objektu Helénská 1799/4, Praha 2",
            "image_folder" :"objekt-helenska",
            "id": "objekt-helenska",
            "imageCount": 0,
            "objednatel" : "Agile Consulting Engineers s.r.o.",
            "investicni_naklady" : "",
            "kratky_popis" : "",
            "popis" : "Geotechnické posouzení základových poměrů",
        },




    ]

};

import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import $ from 'jquery';


import {  withTranslation, Trans } from 'react-i18next';
// import ProjectDetailImage from "./ContentComponents/ProjectDetailImage";
import InfinityLoading from "./img/infinity.svg";
import {Helmet} from "react-helmet";
import Slider from "react-slick/lib";
import mainSlideImg from "./img/mainSlider.jpg";
import {ReferencesData} from "./contentData";


const cache = {};



class ReferencesDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            referenceName: null,
            loaded:true,
            referenceImageFolder: null,
            referencePopis: null,

            referenceObjednatel: null,
            referenceType:"",

            referenceImages: [],
            typeReference: ""
        };

     //   this.recalculateFactsHeights = this.recalculateFactsHeights.bind(this);
    }


    componentDidMount() {
        let url = new URL(window.location.href);
        var urlSplited = window.location.href.toString().split("/");
        var referenceID = urlSplited[4];


        console.log(urlSplited[5]);
        ReferencesData.reference.map((reference, i) =>
        {
            if(reference.id === referenceID){
                this.setState({ referenceName: reference.name, referenceImageFolder: reference.image_folder,
                    referencePopis: reference.popis, referenceObjednatel: reference.objednatel,referenceType: reference.type});


                var images = [];

                for (var i = 0; i < reference.imageCount; i++) {
                    if(reference.type === "reference"){
                        images.push(<img src={"/img/references/"+reference.image_folder+"/"+i+".jpeg"}/>)
                    } else {
                        images.push(<img src={"/img/references/"+reference.image_folder+"/"+i+".PNG"}/>)
                    }

                }
                this.setState({ referenceImages: images});


            }
            // console.log("Entered", this.state.references);
        });
    }




    render() {
        const {i18n, t} = this.props;
        var thisState = this.state;
        var settings = {
            customPaging: function(i) {
                return (
                    thisState.referenceType === "reference" ? <a>
                        <img src={"/img/references/"+thisState.referenceImageFolder+"/"+i+".jpeg"} />
                    </a> : <a>
                        <img src={"/img/references/"+thisState.referenceImageFolder+"/"+i+".PNG"} />
                    </a>

                );
            },
            dots: true,
            infinite: true,
            arrows:false,
            fade:true,
            autoplay:true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,

        };

        if ( !this.state.loaded ) {
            return <div className="loader-people"><img className="infinityLoading" alt="Loading..." src={InfinityLoading}/></div>
        }

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('references.seo.title') ? t('references.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('references.seo.description') ? t('references.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('references.seo.title') ? t('references.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('references.seo.description') ? t('references.seo.description') : ""} />
                </Helmet>

                <div className="container content fade-in-website" id="references-detail">
                    <div className={"row"}>
                    <div className={"col-md-6"}>
                        {this.state.referenceName !== null && this.state.referenceName !== "" && this.state.referenceName !== undefined ?
                            <h1>
                                {this.state.referenceName}
                            </h1>
                            : null}

                        {this.state.referencePopis !== null && this.state.referencePopis !== ""   && this.state.referencePopis !== undefined  ?
                            <div>
                                <h2>
                                    Popis:
                                </h2><br/>
                                <p>
                                    {this.state.referencePopis}
                                </p><br/>
                            </div>
                            : null}

                        {this.state.referenceObjednatel !== null && this.state.referenceObjednatel !== "" && this.state.referenceObjednatel !== undefined ?
                            <div>
                                <h2>
                                    Objednatel:
                                </h2><br/>
                                <p>
                                    {this.state.referenceObjednatel}
                                </p><br/>
                            </div>
                            : null}

                        <button className="back"><a href="/reference/">Zpět na seznam referencí</a></button>

                    </div>



                    <div className={"col-md-6"}>
                        <Slider {...settings}>

                            {this.state.referenceImages.map(image => (
                                <div>
                                    {image}
                                </div>
                            ))}

                        </Slider>
                    </div>

                    </div>
                    <button className="backMobile"><a href="/reference/">Zpět na seznam referencí</a></button>

                </div>

            </div>
        );
    }
}

export default withTranslation()(ReferencesDetail);

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './App.css';
import $ from 'jquery';

import { withTranslation, Trans } from 'react-i18next';

import AgileLogo from "./img/agile-ge-logo.svg";


class Header extends Component {
    constructor(props) {
        super(props);
        //
        // this.timeout = null;
        // this.page = 1;
        // this.requestId = null;
        this.state = {
            secondLevelShowed: false,
            projectsShowed: false,
            referencesShowed: false,
            servicesShowed: false
        };
        //
       this.openSecondLevelMenuServices = this.openSecondLevelMenuServices.bind(this);
        this.openSecondLevelMenuReferences = this.openSecondLevelMenuReferences.bind(this);
        this.openSecondLevelMenuProjects = this.openSecondLevelMenuProjects.bind(this);
        // this.loadMoreProjects = this.loadMoreProjects.bind(this);
    }

    openSecondLevelMenuServices(){
        this.setState({secondLevelShowed: true, servicesShowed: true, referencesShowed: false, projectsShowed: false  });
    }

    openSecondLevelMenuReferences(){
        this.setState({secondLevelShowed: true, referencesShowed: true, servicesShowed: false, projectsShowed: false });
    }

    openSecondLevelMenuProjects(){
        this.setState({secondLevelShowed: true, projectsShowed: true,  referencesShowed: false,  servicesShowed: false });
    }
    componentDidMount() {
        window.headerComponent = this;

var thisX = this;

        var opened = 0;

        $(document).on('click', function(e) {
            console.log(e, e.target.nodeName !== "LI");

            if (e.target.id === 'secondLevel' || e.target.nodeName === "LI" || e.target.nodeName === "UL") {
              //  alert('Div Clicked !!');
            } else {
                if(thisX.state.secondLevelShowed){
                    thisX.setState({secondLevelShowed: false, servicesShowed: false, referencesShowed: false, projectsShowed: false  });
                }

            }

        })

        $(".navbar-toggler").click(function () {
            if (opened === 0) {
                $(".language-switcher").show();
                $(".navbar-collapse").show();
                $(".navbar-collapse").addClass("opacity-1");
                opened = 1;
                return;
            } else {
                $(".language-switcher").hide();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
            }
        });


        $(".main-menu-ul li").click(function () {
            if (opened === 1) {
                $(".language-switcher").hide();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
                return;
            }
        });

        $(".navbar-brand").click(function () {
            if (opened === 1) {
                $(".language-switcher").show();
                $(".navbar-collapse").hide();
                $(".navbar-collapse").removeClass("opacity-1");
                opened = 0;
                return;
            }
        });
    }


    vycistiMenu() {
        $("#main-menu-ul li a").removeClass("active");
    }

    showLanguages(){
        $("#dropdown-language").show();
    }

    getLocalizedRoute(lang) {
        const {i18n, t } = this.props;

        let url = new URL(window.location.href);

        let pathname = url.pathname;

        if (pathname.split('/').length > 2 && pathname.split('/')[2] !== '') {
            pathname = pathname.split('/').slice(0, (i18n.language === 'cs' ? 2 : 3)).join('/');

            let pathLastPart = url.pathname.replace(pathname, '');
            if (this.state && this.state.project && this.state.project.id !== undefined) {
                if (this.state.project.slug !== undefined && this.state.project.slug[lang].trim().length > 0) {
                    pathLastPart = '/' + this.state.project.slug[lang].trim();
                } else {
                    pathLastPart = '/' + this.state.project.id;
                }
            }

            return ('/' + t(window.routes[pathname], { lng: lang })).replace('//', '/') + pathLastPart;
        } else if (['/', '/sk/', '/en/', '/ru/'].includes(pathname)) {
            return (lang !== 'cs'  ? '/' + lang + '/' : '/');
        }

        return  ('/' + t(window.routes[pathname], { lng: lang })).replace('//', '/');
    }

    render() {
        const {i18n, t } = this.props;
        //Uvod, sluzby, reference, projekty, kancelar, kontakt, klienti
        const changeLanguage = lng => {

            i18n.changeLanguage(lng);
        };

        return (
            <div>
                <header className="header">


                    <div className="container header-container">



                        <nav className="navbar navbar-expand-lg">

                                <Link className="navbar-brand" to={"/" + (i18n.language !== 'cs' ? i18n.language + '/' : '')}>
                                    <img src={AgileLogo} className="menu-logo" alt="logo"/>
                                </Link>




                                    <button className="navbar-toggler" type="button">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="" id="navbarSupportedContent">

                                        <ul className="main-menu-ul">
<div>
                                                <li id="home-menu-li"><Link to={"/" + (i18n.language !== 'cs' ? i18n.language + '/' : '')}><Trans i18nKey={'header.link.home'}/></Link></li>

                                                <li id="services-menu-li"><Link to={'/' + t('route.services') } ><Trans i18nKey={'header.link.services'}/></Link></li>

                                                <li id="references-menu-li"><Link to={'/' + t('route.references') } ><Trans i18nKey={'header.link.references'}/></Link></li>
    <li id="office-menu-li"><Link to={'/' + t('route.office') } ><Trans i18nKey={'header.link.office'}/></Link></li>
    <li id="career-menu-li"><Link to={'/' + t('route.career') } ><Trans i18nKey={'header.link.career'}/></Link></li>

    <li id="contact-menu-li"><Link to={'/' + t('route.contact') } ><Trans i18nKey={'header.link.contact'}/></Link></li>


</div>

                                        </ul>

                                    </div>




                        </nav>







                    </div>
                   {/*to={this.getLocalizedRoute('cs')} onClick={() => changeLanguage('cs')}*/}
                    <div className="language-switcher ">
                        <Link className={(i18n.language === 'cs' ? "active" : '')} to >CZ</Link>
                    {/*to={this.getLocalizedRoute('en')}    onClick={() => changeLanguage('en')}*/}
                    <span className={"inactive"}> / </span>
                        <Link className={(i18n.language === 'en' ? "active" : '')}  to>EN</Link>

                    </div>
                </header>

                <div className="collapse navbar-collapse no-transition" id="navbarSupportedContentMobile">

                    <ul className="main-menu-ul">

                        <li id="home-menu-li"><Link to={"/" + (i18n.language !== 'cs' ? i18n.language + '/' : '')}><Trans i18nKey={'header.link.home'}/></Link></li>

                        <li id="services-menu-li"><Link to={'/' + t('route.services') } ><Trans i18nKey={'header.link.services'}/></Link></li>

                        <li id="references-menu-li"><Link to={'/' + t('route.references') } ><Trans i18nKey={'header.link.references'}/></Link></li>

                        <li id="contact-menu-li"><Link to={'/' + t('route.contact') } ><Trans i18nKey={'header.link.contact'}/></Link></li>




                    </ul>

                </div>

                <div id="secondLevel" className={"secondLevel " + (this.state.secondLevelShowed ? "showedSecond " : "")
                    +  (this.state.projectsShowed ? "showedSecondProjects" : "")
                    +  (this.state.referencesShowed ? "showedSecondReferences" : "")
                    +  (this.state.servicesShowed ? "showedSecondServices" : "")}>

                    <div className="container second-header-container">
                        <ul className={"servicesUL"}>
                            <li id="pozemni-stavby-services-li"><Link to={'/' + t('route.servicespozemni') } ><Trans i18nKey={'header.link.servicespozemni'}/></Link></li>
                            <li id="inzenyrske-stavby-services-li"><Link to={'/' + t('route.servicesinzenyrske') } ><Trans i18nKey={'header.link.servicesinzenyrske'}/></Link></li>
                        </ul>
                        <ul className={"referencesUL"}>
                            <li id="pozemni-stavby-references-li"><Link to={'/' + t('route.referencespozemni') } ><Trans i18nKey={'header.link.referencespozemni'}/></Link></li>
                            <li id="inzenyrske-stavby-references-li"><Link to={'/' + t('route.referencesinzenyrske') } ><Trans i18nKey={'header.link.referencesinzenyrske'}/></Link></li>
                        </ul>
                        <ul className={"projectsUL"}>
                            <li id="pozemni-stavby-projects-li"><Link to={'/' + t('route.projectspozemni') } ><Trans i18nKey={'header.link.projectspozemni'}/></Link></li>
                            <li id="inzenyrske-stavby-projects-li"><Link to={'/' + t('route.projectsinzenyrske') } ><Trans i18nKey={'header.link.projectsinzenyrske'}/></Link></li>
                        </ul>



                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation()(Header);

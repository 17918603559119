import React, {Component} from 'react';
import './App.css';


import {  withTranslation, Trans } from 'react-i18next';
import InfinityLoading from "./img/infinity.svg";
// import ProjectTextBox from "./ContentComponents/ProjectTextBox";
import { ReferencesData } from './contentData';

import Helmet from "react-helmet/es/Helmet";
import ReferencesDetailBox from "./components/ReferencesDetailBox";


class References extends Component {

    constructor(props) {
        super(props);

        this.timeout = null;
        this.page = 1;
        this.requestId = null;
        this.state = {
            activeFilters : {
                services : [],
                expertise : [],
                countries : []
            },
            referenceTypes : "",
            filterChanged : false,
            moreReferences : true,
            loadingMore : false,
            noResults : false,
            stateChangin: 1,
            references : [],
            filters : {}
        };

    }


    componentDidMount() {
        const { i18n } = this.props;

        let url = new URL(window.location.href);

        console.log(url.pathname);


            this.setState({
                referenceTypes : "pozemni-stavby",
                references: []
            }, function () {
                var ReferenceDatasArray = [];

                ReferencesData.reference.map((reference, i) =>
                {
                    console.log("Entered", this.state.references);
                    ReferenceDatasArray.push(<ReferencesDetailBox name={reference.name}
                                                              type={"pozemni"}
                                                              link={"/reference/"+reference.id}
                                                              image_folder={reference.image_folder}
                                                              kratky_popis={reference.kratky_popis}
                                                              popis={reference.popis}
                                                              architekt={reference.architekt}
                                                              realizacni_firma={reference.realizacni_firma}
                                                              projektant={reference.projektant}
                                                              investicni_naklady={reference.investicni_naklady}/>);
                    //   var joined = this.state.references.concat( <ReferencesDetailBox name={reference.name} type={"pozemni"} link={"/projekty/pozemni-stavby/projekt1"}/>);
                    // console.log("Entered2", joined);

                    // Return the element. Also pass key
                    //  return (<Answer key={answer} answer={answer} />)
                });
                this.setState({ references: ReferenceDatasArray });
            });




    }

    componentDidUpdate(prevProps, prevState) {



        const { i18n } = this.props;

        let url = new URL(window.location.href);

        console.log(url.pathname);


            if (prevState.stateChangin !== this.state.stateChangin) {
                this.setState({
                    referenceTypes : "pozemni-stavby",
                    references: []
                }, function () {
                    var ReferenceDatasArray = [];

                    ReferencesData.reference.map((reference, i) =>
                    {
                        console.log("Entered", this.state.references);
                        ReferenceDatasArray.push(<ReferencesDetailBox name={reference.name}
                                                                  type={"pozemni"}
                                                                  link={"/reference/pozemni-stavby/"+reference.id}
                                                                  image_folder={reference.image_folder}
                                                                  kratky_popis={reference.kratky_popis}
                                                                  popis={reference.popis}
                                                                  architekt={reference.architekt}
                                                                  realizacni_firma={reference.realizacni_firma}
                                                                  projektant={reference.projektant}
                                                                  investicni_naklady={reference.investicni_naklady}/>);
                        //   var joined = this.state.references.concat( <ReferencesDetailBox name={reference.name} type={"pozemni"} link={"/projekty/pozemni-stavby/projekt1"}/>);
                        // console.log("Entered2", joined);

                        // Return the element. Also pass key
                        //  return (<Answer key={answer} answer={answer} />)
                    });
                    this.setState({ references: ReferenceDatasArray });

                });
            }

    }



    render() {
        const { i18n, t } = this.props;




        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('references.seo.title') ? t('references.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('references.seo.description') ? t('references.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('references.seo.title') ? t('references.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('references.seo.description') ? t('references.seo.description') : ""} />
                </Helmet>

                    <div className="container content fade-in-website" id="references">
                        <div id={"references-filter"}>
                        </div>
                        <div>
                            {/*{this.state.references}*/}
                            <h1>Vybrané reference</h1>
                            <h3>Inženýrská geologie a diagnostika stavebních konstrukcí</h3>
                           <br/>

                            <span>
                                Akce:       <b>PVA Praha - výstavní hala 1       </b>  <br/>
                                Objednatel: PVA EXPO a.s.   <br/>
                                Popis:  <span className={"green"}>Podrobný inženýrskogeologický průzkum pro založení nové haly   </span>   <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>Novostavba administrativního, obchodního a logistického centra Maldá Boleslav - Řepov</b>    <br/>
                                Objednatel: MO Atelier s.r.o.   <br/>
                                Popis: <span className={"green"}>Inženýrskogeologický a hydrogeologický průzkum </span>   <br/>   <br/>
                            </span>

                            <span>
                                Akce:<b> Novostavba bytového domu na pozemcích parc. č. 320 a 321, k.ú. Ďáblice </b>   <br/>
                                Objednatel: MO Atelier s.r.o.   <br/>
                                Popis: <span className={"green"}>Inženýrskogeologický, hydrogeologický průzkum a radonový průzkum </span>    <br/>   <br/>
                            </span>
                            <span>
                                Akce: <b>Stavební úpravy domu č.p. 107, Malešické náměstí 8 v Praze 10 - Malešicích </b>  <br/>
                                Objednatel: Agile Consulting Engineers s.r.o.   <br/>
                                Popis: <span className={"green"}>Geotechnický a stavebně - technický průzkum    </span> <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>Rezidence Helénská, č. parc. 2260 a 2261 - Rekonstrukce objektu Helénská 1799/4, Praha 2 </b>  <br/>
                                Objednatel: Agile Consulting Engineers s.r.o.   <br/>
                                Popis:<span className={"green"}> Geotechnické posouzení základových poměrů </span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>II/285 Slavoňov – Nový Hrádek PD</b>  <br/>
                                Objednatel: Královéhradecký kraj  <br/>
                                Popis:<span className={"green"}> Inženýrskogeologický průzkum pro SO 201 – opěrná zeď </span>  <br/>   <br/>
                            </span>


                            <span>
                                Akce: <b>D8 Rozšíření odpočívky Varvažov v km 77,9 – aktualizace TS</b>  <br/>
                                Objednatel: Ředitelství silnic a dálnic ČR <br/>
                                Popis:<span className={"green"}> Rešerše GTP</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>II/105 Kabáty, opěrná zeď</b>  <br/>
                                Objednatel: Krajská správa a údržba silnic Středočeského kraje<br/>
                                Popis:<span className={"green"}> Podrobný geotechnický průzkum</span>  <br/>   <br/>
                            </span>


                            <span>
                                Akce: <b>Silnice III/27018 Kněžice – Lvová rekonstrukce silnice</b>  <br/>
                                Objednatel: Krajská správa silnic Libereckého kraje<br/>
                                Popis:<span className={"green"}> Geotechnický a stavebně technický průzkum mostů ev. č. 27018-1 a 27018-2</span>  <br/>   <br/>
                            </span>


                            <span>
                                Akce: <b>II/334 Sadská - Milčice</b>  <br/>
                                Objednatel: Krajská správa a údržba silnic Středočeského kraje<br/>
                                Popis:<span className={"green"}> Stavebně technický a geotechnický průzkum pro mosty ev. č. 334-002, 334-002a, 334-003</span>  <br/>   <br/>
                            </span>
                            <span>
                                Akce: <b>I/62 Roztoky - PHS</b>  <br/>
                                Objednatel: Ředitelství silnic a dálnic ČR<br/>
                                Popis:<span className={"green"}> Inženýrskogeologický průzkum</span>  <br/>   <br/>
                            </span>

                            <h3>Mosty a inženýrské konstrukce</h3>
<br/>


                            <span>
                                Akce: <b>II/105 Kabáty, opěrná zeď</b>  <br/>
                                Objednatel:  Krajská správa a údržba silnic Středočeského kraje<br/>
                                Popis:<span className={"green"}> SO 251 Opěrná zeď, SO 252 Opěrná zeď (DUSP, PDPS, RDS, AD)</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>Silnice III/27018 Kněžice – Lvová rekonstrukce silnice</b>  <br/>
                                Objednatel: Krajská správa silnic Libereckého kraje<br/>
                                Popis:<span className={"green"}> SO 201 Most ev.č. 27018-1, SO 202 Most ev.č. 27018-2, SO 203 Most ev.č. 27018-5
                            (DUSP/PDPS)</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>II/334 Sadská - Milčice</b>  <br/>
                                Objednatel: Krajská správa a údržba silnic Středočeského kraje<br/>
                                Popis:<span className={"green"}> SO 201 most ev. č. 334-002, SO 202 most 334-002a, SO 203 most 334-003 (DUR, DSP)</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>III/0066, III/00711, III/00716, Hřebeč, rekonstrukce silnic - PD</b>  <br/>
                                Objednatel: Krajská správa a údržba silnic Středočeského kraje<br/>
                                Popis:<span className={"green"}> SO 201 Opěrná gabionová zeď (DUSP, PDPS)</span>  <br/>   <br/>
                            </span>


                            <span>
                                Akce: <b>II/285 Slavoňov – Nový Hrádek PD</b>  <br/>
                                Objednatel: Královéhradecký kraj<br/>
                                Popis:<span className={"green"}> SO 201 – opěrná zeď (DUSP/PDPS)</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>Rekonstrukce silnic u hráze VD Orlík</b>  <br/>
                                Objednatel: Krajská správa a údržba silnic Středočeského kraje<br/>
                                Popis:<span className={"green"}> Vybrané objekty opěrných zdí, úprava skalních výchozů (DSP, PDPS)</span>  <br/>   <br/>
                            </span>




                            <span>
                                Akce: <b>I/62 Roztoky - PHS</b>  <br/>
                                Objednatel: Ředitelství silnic a dálnic ČR<br/>
                                Popis:<span className={"green"}> SO 760 Protihluková stěna 1, SO 761 Protihluková stěna 2 (DUSP)</span>  <br/>   <br/>
                            </span>



                            <span>
                                Akce: <b>III/11220 Borovnice mosty ev.č. 11220-1 a 11220-2</b>  <br/>
                                Objednatel: Krajská správa a údržba silnic Středočeského kraje<br/>
                                Popis:<span className={"green"}> SO 201 Rekonstrukce mostu ev.č. 11220-1 a SO 202 Rekonstrukce mostu ev.č. 11220-2 (RDS)</span>  <br/>   <br/>
                            </span>


                            <span>
                                Akce: <b>III/11220 Borovnice mosty ev.č. 11220-1 a 11220-2</b>  <br/>
                                Objednatel: Krajská správa a údržba silnic Středočeského kraje<br/>
                                Popis:<span className={"green"}> SO 201 Rekonstrukce mostu ev.č. 11220-1 a SO 202 Rekonstrukce mostu ev.č. 11220-2 (RDS)</span>  <br/>   <br/>
                            </span>


                           <h3>Vodohospodářské stavby</h3>

                            <br/>
                            <span>
                                Akce: <b>VD Letovice, rekonstrukce VD</b>  <br/>
                                Objednatel: Povodí Moravy s.p., Sweco Hydroprojekt a.s.<br/>
                                Popis:<span className={"green"}> SO 05 Přemostění skluzu (PDPS, RDS)</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>Využití energetického potencionálu vedlejších proudů v Ethanol Energy a.s.</b>  <br/>
                                Objednatel: Ethanol Energy a.s.<br/>
                                Popis:<span className={"green"}> Návrh ŽB a OC konstrukcí v rámci tohoto projektu (DSP, RDS)</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>ČOV Černovice</b>  <br/>
                                Objednatel: Obec Černovice<br/>
                                Popis:<span className={"green"}> Návrh ŽB konstrukcí pro ČOV. (DSP, RDS)</span>  <br/>   <br/>
                            </span>



                            <span>
                                Akce: <b>Intenzifikace ČOV v areálu FTT Investments, s.r.o. Číčenice</b>  <br/>
                                Objednatel: FTT Investments, s.r.o.<br/>
                                Popis:<span className={"green"} > Návrh ŽB konstrukcí pro ČOV. (DSP, RDS)</span>  <br/>   <br/>
                            </span>


                            <span>
                                Akce: <b>Dosazovací nádrž ČOV AGRIS Mankovice</b>  <br/>
                                Objednatel: Agris spol. s r.o.<br/>
                                Popis:<span className={"green"}> Návrh ŽB konstrukcí pro ČOV. (DSP, RDS)</span>  <br/>   <br/>
                            </span>


                            <span>
                                Akce: <b>Obnova ekosystému odstavených ramen řeky Moravy</b>  <br/>
                                Objednatel:  Hydro a Kov s.r.o.<br/>
                                Popis:<span className={"green"}> SO 01 – Rameno Čerták 1 – Vtokový objekt (RDS)</span>  <br/>   <br/>
                            </span>
<h3>Tunely a geotechnika</h3>
                            <br/>
                            <span>
                                Akce: <b>Bystrá, Praha 20, č. akce 999547</b>  <br/>
                                Objednatel:  Technická správa komunikací hl. m. Prahy<br/>
                                Popis:<span className={"green"}> SO 302.1 Dešťová kanalizace, odpad do DUN – ražená štola (DUR)</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>I/20 Plzeň, Jateční – Na Roudné</b>  <br/>
                                Objednatel: Ředitelství silnic a dálnic ČR<br/>
                                Popis:<span className={"green"}> SO 601 Kolektor pro horkovod, SO 602 Kabelovod u ul. Doubravecké, Dešťová kanalizace - ČPHZ SO 312 (DUR)</span>  <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>Přeložka kanalizace při ul. Radlická</b>  <br/>
                                Objednatel: Investiční DELTA a.s.<br/>
                                Popis:<span className={"green"}> Těžní šachty a tunely (PDPS, RDS, AD)</span><br/>
                                <a href={"https://www.casopisstavebnictvi.cz/clanky-prelozka-kanalizacni-stoky-pod-prazskou-novou-waltrovkou.html"} target={"_blank"}>Časopis stavebnictví </a><br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>Rekonstrukce TWY H mezi TWYs H1-J</b>  <br/>
                                Objednatel: Letiště Praha a.s.<br/>
                                Popis:<span className={"green"}> SO 601 Oprava kabelových šachet (DSP/PDPS)</span> <br/>   <br/>
                            </span>
                            <span>
                                Akce: <b>Rekonstrukce kanalizace, ul. Markétská, Praha 6</b>  <br/>
                                Objednatel: Pražská vodohospodářská společnost, a.s.<br/>
                                Popis:<span className={"green"}> Činnost prováděná hornickým způsobem</span> <br/>   <br/>
                            </span>

                            <span>
                                Akce: <b>Modernizace a dostavba ŽST Praha Masarykovo nádraží</b>  <br/>
                                Objednatel: Správa železnic, státní organizace<br/>
                                Popis:<span className={"green"}> SO 11-31-01 – úpravy dešťové kanalizace, SO 11-32-04 – úpravy vodovodního kolektoru PVK</span> <br/>   <br/>
                            </span>



                        </div>
                    </div>
            </div>
        );
    }
}

export default withTranslation()(References);

import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';


import {withTranslation, Trans} from "react-i18next";

import {Helmet} from "react-helmet";

class Career extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;


    }

    componentDidMount() {

    }


    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('career.seo.title') ? t('career.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('career.seo.description') ? t('career.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('career.seo.title') ? t('career.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('career.seo.description') ? t('career.seo.description') : ""} />
                </Helmet>

                <div className="container content fade-in-website clients" id="career">

                    <span>Agile Geotechnics hledá další členy týmu v oborech pozemních staveb a mostních a inženýrských konstrukcí.</span>
                    <br/> <br/>
                    <h3>Nabízíme:</h3>

                    <ul>
                        <li>Flexibilitu pracovního poměru, možnost práce z domova (na kvalifikovanějších pozicích)</li>
                        <li>Nadstandardní finanční ohodnocení</li>
                        <li>Zázemí rychle se rozvíjející progresivní firmy</li>
                        <li>Přístup k modernímu softwarovému vybavení</li>
                        <li>Investice do profesního rozvoje</li>
                        <li>Zaměstnanecké benefity – dohodou dle pozice a pracovního poměru, např. příspěvek na stravné, příspěvek na telefon, Lítačka, sickdays, prémie, apod.</li>
                    </ul>

                        <h3 style={{textDecoration:"underline"}}>V současné době hledáme kolegy zejména na tyto pozice:</h3> <br/>

                        <h3> Projektant v oboru mosty a inženýrské konstrukce</h3>
                    <br/> <br/>

                    <span>V případě zájmu nás kontaktujte na adrese <a href={"mailto:info@agile-ge.cz"}>info@agile-ge.cz</a> se shrnutím Vašich dosavadních zkušeností a vzdělání v oboru ve formě krátkého CV. Důležitá je pro nás osobní schůzka a prezentace výsledků Vaší práce.</span>
                    <br/> <br/><span>Agile GE se řídí zásadou rovných příležitostí pro všechny své zaměstnance i externí spolupracovníky.</span>

                </div>
            </div>
        );
    }
}

export default withTranslation()(Career);

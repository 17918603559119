import React, { Component } from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import { Route, Router } from 'react-router-dom';


import jquery from 'jquery';

import MainHome from "./MainHome";
import GDPR from "./GDPR";
import References from "./References";

import Contact from "./Contact";
import Clients from "./Clients";
import Projects from "./Projects";
import ProjectsDetail from "./ProjectsDetail";
import ReferencesDetail from "./ReferencesDetail";
import Office from "./Office";
import Services from "./Services";
import Projection from "./Projection";
import Career from "./Career";
import PrivacyPolicy from "./PrivacyPolicy";
import {Trans, withTranslation} from "react-i18next";
import {Helmet} from "react-helmet";


const createHistory = require("history").createBrowserHistory;

const $ = jquery;

const history = createHistory();

window.routes = {};



class Home extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            eventId: null
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });

    }

    componentWillMount() {
        const { i18n,t } = this.props;
        let url = new URL(window.location.href);
        switch (url.pathname.slice(0, 3)) {
            case '/en': i18n.changeLanguage('en'); break;
            case '/sk': i18n.changeLanguage('sk'); break;
            case '/ru': i18n.changeLanguage('ru'); break;
            default: i18n.changeLanguage('cs');
        }

        this.unlisten = history.listen((location, action) => {
            $("#home-menu-li").removeClass("active");

            $("#services-menu-li").removeClass("active");
            $("#references-menu-li").removeClass("active");
            $("#projects-menu-li").removeClass("active");
            $("#office-menu-li").removeClass("active");
            $("#career-menu-li").removeClass("active");
            $("#contact-menu-li").removeClass("active");
            $("#clients-menu-li").removeClass("active");


            if(window.location.pathname.length === 1){
                $("#home-menu-li").addClass("active");
            }

            console.log(window.location.pathname, t('route.services'), t('route.references'));
            if(window.location.pathname.includes(t('route.services') )){
                $("#services-menu-li").addClass("active");
            }
            if(window.location.pathname.includes(t('route.references'))){
                $("#references-menu-li").addClass("active");
            }
            if(window.location.pathname.includes(t('route.projects'))){
                $("#projects-menu-li").addClass("active");
            }
            if(window.location.pathname.includes(t('route.office'))){
                $("#office-menu-li").addClass("active");
            }
            if(window.location.pathname.includes(t('route.career'))){
                $("#career-menu-li").addClass("active");
            }
            if(window.location.pathname.includes(t('route.contact'))){
                $("#contact-menu-li").addClass("active");
            }
            if(window.location.pathname.includes(t('route.clients'))){
                $("#clients-menu-li").addClass("active");
            }

        });


    }



    componentDidMount(){
        const { t } = this.props;
        //Uvod, sluzby, reference, projekty, kancelar, kontakt, klienti

        $("#home-menu-li").removeClass("active");

        $("#services-menu-li").removeClass("active");
        $("#references-menu-li").removeClass("active");
        $("#projects-menu-li").removeClass("active");
        $("#office-menu-li").removeClass("active");
        $("#career-menu-li").removeClass("active");
        $("#contact-menu-li").removeClass("active");
        $("#clients-menu-li").removeClass("active");


        if(window.location.pathname.length === 1){
            $("#home-menu-li").addClass("active");
        }

        console.log(window.location.pathname, t('route.services'));
        if(window.location.pathname.includes(t('route.services') )){
            $("#services-menu-li").addClass("active");
        }
        if(window.location.pathname.includes(t('route.references'))){
            $("#references-menu-li").addClass("active");
        }
        if(window.location.pathname.includes(t('route.projects'))){
            $("#projects-menu-li").addClass("active");
        }
        if(window.location.pathname.includes(t('route.office'))){
            $("#office-menu-li").addClass("active");
        }
        if(window.location.pathname.includes(t('route.career'))){
            $("#career-menu-li").addClass("active");
        }
        if(window.location.pathname.includes(t('route.contact'))){
            $("#contact-menu-li").addClass("active");
        }
        if(window.location.pathname.includes(t('route.clients'))){
            $("#clients-menu-li").addClass("active");
        }

    }

  render() {
      const {i18n, t } = this.props;

      let routes = window.routes;
      routes['/'] = '';
      routes['/' + t("route.services")] = 'route.services';
      routes['/' + t("route.references")] = 'route.references';
      routes['/' + t("route.projects")] = 'route.projects';
      routes['/' + t("route.office")] = 'route.office';
      routes['/' + t("route.contact")] = 'route.contact';
      routes['/' + t("route.clients")] = 'route.clients';





      routes['/' + t("route.privacy-policy")] = 'route.privacy-policy';
      routes['/' + t("route.terms-of-use")] = 'route.terms-of-use';
      routes['/' + t("route.gdpr")] = 'route.gdpr';


      return (
        <Router history={history}>
            <div className="App">
                <Helmet>
                    <title>{i18n.exists('seo.title') ? t('seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('seo.description') ? t('seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('seo.title') ? t('seo.title') : ""} />
                    <meta property="og:description" content={i18n.exists('seo.description') ? t('seo.description') : ""} />
                </Helmet>
                <Header value={this.state.value} />
                <Route exact path={'/' + (i18n.language !== 'cs' ? i18n.language + '/' : '')}  component={MainHome} />
                <Route exact path={'/' +  t('route.services')   } component={Services} />
                <Route exact path={'/' +  t('route.references')   } component={References} />
                <Route exact path={'/' +  t('route.references') + '/:id' } component={ReferencesDetail} />

                {/*

                <Route exact path={'/' +  t('route.projects') + '/:id'} component={Projects} />
*/}


{/*

                <Route exact path={'/' +  t('route.references') + '/:id'} component={References} />
*/}

         {/*
                <Route exact path={'/' +  t('route.projects') + '/:id' + '/:id'} component={ProjectsDetail} />*/}

                <Route exact path={'/' +  t('route.office') + '/'} component={Office} />
                <Route exact path={'/' +  t('route.contact')  }  component={Contact} />
                {/*<Route exact path={'/' +  t('route.projection')  }  component={Projection} />*/}
                {/*<Route exact path={'/' +  t('route.clients')  }  component={Clients} />*/}
                <Route exact path={'/' +  t('route.career')  }  component={Career} />


                <Route exact path={'/' +  t('route.privacy-policy')  } component={PrivacyPolicy} />
                <Route exact path={'/' +  t('route.gdpr')  } component={GDPR} />

                <Footer />
            </div>
        </Router>
      );
  }
}

export default  withTranslation()(Home);
import React, { Component } from 'react';

import './App.css';
import linkedinIcon from "./img/linkedin.png";
import instagIcon from "./img/instag.png";

import $ from "jquery";
import {withTranslation, Trans} from "react-i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();




class Footer extends Component {
    constructor(props){
        super(props);
        this.acceptCookies = this.acceptCookies.bind(this);
    }
    componentDidMount() {

        if(cookies.get('dolniLista') === undefined){
            cookies.set('dolniLista', "false", { path: '/' });
        }

        this.checkCookies();
    }


    checkCookies(){

        if(cookies.get('dolniLista') === "true"){

            $("#cookie-lista").hide();
        }
    }

    acceptCookies(){
        cookies.set('dolniLista', "true", { path: '/' });
        this.checkCookies();
    }
    render() {
      const { t } = this.props;
    return (
<div>

    <footer className="footer">



        <div className="container-fluid" id="cookie-lista">
            <div className="container">
                <span dangerouslySetInnerHTML={{__html: t('footer.cookies-lista.text', {interpolation: {escape: false}})}} />
                <button onClick={this.acceptCookies} dangerouslySetInnerHTML={{__html: t('footer.cookies-lista.button', {interpolation: {escape: false}})}} />
            </div>
        </div>

        <div className="container footer-container">

            <div className="row">
                <div>
                    <span>Agile Geotechnics s.r.o.</span>
                    <span className={"delic"}>|</span>
                </div>


                <div>
                    <span>Na Vyhlídce 286/64, Praha 9</span>
                    <span className={"delic"}>|</span>
                </div>


                <div>
                    <span><a href={"mailto:info@agile-ge.cz"}>info@agile-ge.cz</a></span>
                    <span className={"delic"}>|</span>
                </div>

                <div>
                    <span><a href={"tel:+420 733 386 555"}>+420 733 386 555</a></span>
                    <span className={"delic"}>|</span>
                    <br className={"hiddenDeskTab"}/>
                </div>



                <div>
                    <span><a href={"tel:+420 230 234 528"}>+420 230 234 528</a></span>
                    <span className={"delic hiddenDesktop"}>|</span>
                </div>


                <div className={"socials"}>

                    <a href={"https://www.linkedin.com/"}><img className={"linkedFooter"} src={linkedinIcon}/></a>
  {/*                  <a href={"https://www.linkedin.com/"}><img src={instagIcon}/></a>*/}
                </div>


        </div>



        </div>


    </footer>

</div>
    );
  }
}

export default withTranslation()(Footer);

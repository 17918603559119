import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';


import {withTranslation, Trans} from "react-i18next";

import {Helmet} from "react-helmet";

import ContactIMG from "./img/silueta.jpg";



class Contact extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;

        this.state = {
            officeManagers : [],
            loaded : false,
            formSending : false,
            formSentSuccessfully : false,
            formError : null,
            formInvalidFields : {}
        };

        this.onSubmit = this.onSubmit.bind(this);

        t('contact.form.validation.empty');
        t('contact.form.validation.invalid_email');
        t('contact.form.validation.gdpr-invalid');
    }

    componentDidMount() {

    }

    onSubmit(event) {
        event.preventDefault();
        const { i18n } = this.props;
        const self = this;
        self.setState({
            formSending : true
        });
        let $form = $(event.target);

        $.ajax({
            'method' : 'post',
            'data' : $form.serialize(),
            'url' : window.API + '/contact/send-message?locale=' + i18n.language,
            'success' : function(data) {

                if(data.status === 'success') {
                    self.setState({
                        formSending : false,
                        formSentSuccessfully : true
                    });
                    $form[0].reset();
                    setTimeout(function() { self.setState({ formSentSuccessfully : false }); }, 10000);
                } else {
                    self.setState({
                        formSending : false,
                        formSentSuccessfully : false,
                        formError : data.message,
                        formInvalidFields : data.errors
                    });
                }
            }
        });

        return false;
    }

     iframe () {
        return {
            __html: '  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2558.3535809949963!2d14.490698515719329!3d50.11710527943075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470becbb05636ffd%3A0x5138995f806a23d9!2sNa%20Vyhl%C3%ADdce%20286%2C%20190%2000%20Praha%209-Prosek!5e0!3m2!1scs!2scz!4v1643285775573!5m2!1scs!2scz"\n' +
                '                                width="100%" height="400" style="border:0;" allowFullScreen="" loading="lazy"></iframe>'
        }
    }
    render() {
        const {i18n, t} = this.props;

        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('contact.seo.title') ? t('contact.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('contact.seo.description') ? t('contact.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('contact.seo.title') ? t('contact.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('contact.seo.description') ? t('contact.seo.description') : ""} />
                </Helmet>
                <div className="container content fade-in-website contact" id="contact">

                    <div className={"row"}>


                    </div>
                    <div className="row">


                        <div className="siluetaIMG">


                            <img src={ContactIMG} className="contact-image" alt="logo"/>
                        </div>
                        <div className="col-lg-6">



                        </div>

                    </div>
                    <div className="row ">
                        <br/><br/>


                        <div className={"col-md-4"}>

                            <h4> Ing. Pavel Roubal</h4>
                            <span>průzkumy staveb</span>
                            <span>&#8205;</span><br/><br/><br/>
                            <span>E-mail: <a href={"mailto:kancelar@agile-ge.cz"}>kancelar@agile-ge.cz</a></span><br/>
                            <span>Telefon: <a href={"tel:+420 606 716 699"}>+420 606 716 699</a></span>

                        </div ><br/>
                        <div className={"col-md-4"}>
                            <h4>Jan Tomšů, MSc CEng</h4>
                            <span>inženýrské stavby</span><br/><br/><br/>
                            <span>E-mail: <a href={"mailto:info@agile-ge.cz"}>info@agile-ge.cz</a></span><br/>
                            <span>Telefon: <a href={"tel:+420 736 535 478"}>+420 736 535 478</a></span>

                        </div><br/>
                        <div className={"col-md-4"}>
                            <h4>Ing. Petr Tomáš</h4>
                            <span>inženýrské stavby, geotechnika (AI), <br/>báňský projektant</span><br/><br/>
                            <span>E-mail: <a href={"mailto:petr.tomas@agile-ge.cz"}>petr.tomas@agile-ge.cz</a></span><br/>
                            <span>Telefon: <a href={"tel:+420 778 486 915"}>+420 778 486 915</a></span>

                        </div>


                    </div>
                    <div className={"row globalinfo"}>


                        <div className={"col-md-4"}>
                            <h5>
                                Sídlo společnosti<br/>
                                Šumavská 1036/23<br/>
                                120 00 Praha 2 - Vinohrady<br/>
                            </h5>
                            <br/>
                            <h5>
                                Kancelář<br/>
                                Na Vyhlídce 286/64  <br/>
                                190 00 Praha 9<br/>
                            </h5>
                            <span>
                              vstup z ulice U mateřské školy <br/> <br/>
                            </span>
                        </div>


                        <div className={"col-md-4"}>
                            <h5> Agile Geotechnics s.r.o.
                            </h5>
                            <h5>
                                IČO: 095 06 705<br/>
                                DIČ: CZ095 06 705
                            </h5>
                            <span>
                            Spisová značka C 337298 vedená u Městského soudu v Praze
                            </span>

                           <br/> <br/>
                            <h5>
                                E-mail: <a href={"mailto:info@agile-ge.cz"}>info@agile-ge.cz</a><br/>
                                Mobil: <a href={"tel:+420 739 650 229"}>+420 739 650 229</a><br/>
                                DS: y3zjbcj
                            </h5>

                        </div>
                        <div className={"col-md-4"}>



                            <h5>
                                č. ú: 5913218349/0800<br/>
                                Česká spořitelna, a.s.
                            </h5>
                            <br/> <span>
                            Faktury zasílat na: <br/>
                              <a href={"mailto:kancelar@agile-ge.cz"}>kancelar@agile-ge.cz</a>
                            </span>

                        </div>



                    </div>
                    <div className="row mapa" >
                        <div className="col-lg-12" dangerouslySetInnerHTML={ this.iframe() } />
                    </div>




                </div>


            </div>
        );
    }
}

export default withTranslation()(Contact);

import React, {Component} from 'react';
import './App.css';
import $ from 'jquery';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import { withTranslation, Trans } from 'react-i18next';

import mainSlideImg from "./img/mainSlider.jpg";

import mainSlideImg1 from "./img/slider/1_n.jpg";
import mainSlideImg2 from "./img/slider/2_n.jpg";
import mainSlideImg3 from "./img/slider/3_n.jpg";
import mainSlideImg4 from "./img/slider/4_n.jpg";






import {Helmet} from "react-helmet";



class MainHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            projects: [],
            services: [],
            expertise: [],
            homepageHero : {}
        }
    };


    componentDidMount() {
        $(window).scrollTop(0);

    }

    render() {
        const {i18n, t} = this.props;
        var settings = {
            dots: false,
            infinite: true,
            arrows:false,
            fade:true,
            autoplay:true,
            speed: 3500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
        };
        return (
            <div>
                <Helmet>
                    <title>{i18n.exists('home.seo.title') ? t('home.seo.title') : "Agile GE"}</title>
                    <meta name="description" content={i18n.exists('home.seo.description') ? t('home.seo.description') : ""} />
                    <meta property="og:title" content={i18n.exists('home.seo.title') ? t('home.seo.title') : "Agile GE"} />
                    <meta property="og:description" content={i18n.exists('home.seo.description') ? t('home.seo.description') : ""} />
                </Helmet>
                <div className="container content fade-in-website home" id="home">

                    <h1 style={{textAlign: "center"}}>„Spojení poznatků geologie – hydrogeologie – mechaniky zemin a&nbsp;hornin – zakládání staveb a podzemního stavitelství“</h1>
                    <div>

                        <Slider {...settings}>


                            <div>
                                <img src={mainSlideImg1}/>
                            </div>
                            <div>
                                <img src={mainSlideImg2}/>
                            </div>
                            <div>
                                <img src={mainSlideImg3}/>
                            </div>
                            <div>
                                <img src={mainSlideImg4}/>
                            </div>


                        </Slider>
                    </div>

                    <span >Agile Geotechnics je zaměřena na práce spojené s&nbsp;inženýrskou geologií a stavebně technickými
                            průzkumy. Nabízíme konzultace v&nbsp;rámci IGP a STP, projekty zaměřené na geotechniku. Dále
                            zpracovává projekty pro činnost prováděnou hornickým způsobem dle vyhlášky č. 55/1996. </span>

                </div>



            </div>
        );
    }
}

export default withTranslation()(MainHome);
